import { Flex, Switch } from "antd";
import useDataGridStore from "stores/dataGridStore";
import LockedSwitchOverlay from "./filters/selectFilters/lockedFilterComps/lockedSwitchOverlay";
import { useEffect, useState } from "react";
import LockedSwitchModal from "./filters/selectFilters/lockedFilterComps/lockedSwitchModal";
import { SwitchChangeEventHandler } from "antd/lib/switch";

const CheckBoxRowToggle = () => {
  const { setCheckBoxColumnActive, checkboxColumnActive, updateStack, setSelectedRows } =
    useDataGridStore();
    const [lockedMenu, setLockedMenu] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
      if (updateStack.length > 0) {
        setLockedMenu(true);
      }
    }, [updateStack]);

    const handleUnlockClick = () => {
      setIsModalVisible(true);
    };
  
    const confirmUnlock = () => {
      setLockedMenu(false);
      setIsModalVisible(false);
    };
  
    const cancelUnlock = () => {
      setIsModalVisible(false);
    };

    const handleToggle = (checked: boolean) => {  
      if (!checked) {
        setSelectedRows({});
      }
      setCheckBoxColumnActive(checked)
    }

  return (
    <Flex style={{position: "relative", height: "100%", alignItems: "center"}}>
      <Switch
        style={{ maxWidth: "44px", width: "auto" }}
        disabled={updateStack.length > 0}
        value={checkboxColumnActive}
        onChange={handleToggle}
      />
      <LockedSwitchOverlay
        lockedMenu={updateStack.length > 0}
        handleUnlockClick={handleUnlockClick}
      />
      <LockedSwitchModal
        isModalVisible={isModalVisible}
        confirmUnlock={confirmUnlock}
        cancelUnlock={cancelUnlock}
      />
    </Flex>
  );
};

export default CheckBoxRowToggle;
