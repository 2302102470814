import { Flex, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import DataGridModal from "components/modals/dataGridModal";
const { Title } = Typography;

const TextModalWithCompareText = ({
  modalTitle,
  mainTextTitle,
  editorProps,
  compareText,
  compareTextTitle,
}: {
  modalTitle?: string;
  mainTextTitle?: string;
  editorProps: any;
  compareText: string;
  compareTextTitle: string;
}) => {
  const onClose = () => {
    editorProps.cell.stopEdit();
  };
  const onSave = async () => {
    await editorProps.onComplete();
  };

  return (
    <DataGridModal
      isOpen={editorProps?.cell?.isInEdit()}
      onClose={onClose}
      onSave={onSave}
      title={modalTitle || editorProps?.cellProps?.header}
      width={1000}
    >
      <Flex wrap="wrap" gap="small" style={{ width: '100%' }}>
        <div style={{ flex: 1, minWidth: '280px' }}>
          <p style={{ marginBottom: "0px" }}>{mainTextTitle || editorProps?.cellProps?.header}</p>
          <TextArea
            rows={20}
            spellCheck="true"
            defaultValue={editorProps?.value}
            onChange={editorProps?.onChange}
            style={{ width: '100%' }}
          />
        </div>
        <div style={{ flex: 1, minWidth: '280px' }}>
          <p style={{ marginBottom: "0px" }}>{compareTextTitle || ""}</p>
          <TextArea
            disabled
            rows={20}
            defaultValue={compareText}
            style={{ 
              width: '100%',
              color: 'rgba(0, 0, 0, 0.50)',
            }}
          />
        </div>
      </Flex>
    </DataGridModal>
  );
};

export default TextModalWithCompareText;
