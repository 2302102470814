import { ReloadOutlined, SaveOutlined, UndoOutlined } from "@ant-design/icons";
import { Button, Flex, Tooltip } from "antd";
import useSaveDataGridData from "components/hooks/dataGrid/useSaveDataGridData";
import useDataGridStore from "stores/dataGridStore";
import { useFetchProductsByFilterStr } from "components/hooks/dataGrid/useDataGridSource";
import useHandleStackUndo from "components/hooks/dataGrid/useHandleStackUndo";
import InformationModalWithButton from "../modals/informationModal";

const ToolBarButtons = () => {
  const {
    updateStack,
    dataSourceIsLoading,
    updateDataSourceIsLoading,
    dataGridfilterStr,
  } = useDataGridStore();
  const { saveDataGridData } = useSaveDataGridData();
  const { fetchDataGridProducts } = useFetchProductsByFilterStr();
  const { handleUndoStackUpdate } = useHandleStackUndo();

  return (
    <Flex
      gap={15}
      justify="center"
      style={{ position: "relative", alignItems: "end" }}
    >
      <Tooltip
        color={"gray"}
        placement="topRight"
        title={"Ångra din senaste tabellredigering."}
      >
        <Button
          onClick={handleUndoStackUpdate}
          type="default"
          icon={<UndoOutlined />}
          disabled={updateStack.length === 0}
          loading={dataSourceIsLoading}
        >
          Ångra senaste
        </Button>
      </Tooltip>
      <Tooltip
        color={dataGridfilterStr ? "red" : "gray"}
        placement="topRight"
        title={"Alla osparade ändringar försvinner vid omladdning."}
      >
        <Button
          onClick={() => fetchDataGridProducts()}
          type="primary"
          danger
          icon={<ReloadOutlined />}
          disabled={
            dataSourceIsLoading || !dataGridfilterStr || updateDataSourceIsLoading
          }
          loading={dataSourceIsLoading}
        >
          Ladda om
        </Button>
      </Tooltip>

      <Button
        onClick={saveDataGridData}
        disabled={
          updateStack?.length === 0 ||
          updateDataSourceIsLoading ||
          dataSourceIsLoading
        }
        loading={updateDataSourceIsLoading}
        type="primary"
        size="middle"
        icon={<SaveOutlined />}
      >
        Spara
      </Button>
      <InformationModalWithButton />
    </Flex>
  );
};

export default ToolBarButtons;
