import React, { useEffect, useState } from "react";
import useWorkTaskOpenApi from "apiClients/useWorkTaskOpenApiClient";
import { Button, Input, Modal } from "antd";

interface AddWorkTaskProductsModalProps {
  visible: boolean;
  onClose: () => void;
  onAddProducts: (articleNumbers: string[]) => void;
  workTaskId?: number;
}

const AddWorkTaskProductsModal: React.FC<AddWorkTaskProductsModalProps> = ({
  visible,
  onClose,
  onAddProducts,
  workTaskId
}) => {
  const { getWorkTaskById, loading } = useWorkTaskOpenApi();
  const [articleNumbers, setArticleNumbers] = useState<string>("");
  const [isFetched, setIsFetched] = useState<boolean>(false);

  useEffect(() => {
    const fetchWorkTask = async () => {
      if (workTaskId) {
        const workTask = await getWorkTaskById(workTaskId);

        const articleNumbers = workTask?.item?.items?.map((item) => {
          return item.product.mainItemNo;
        }).join("\n") ?? "";

        setArticleNumbers(articleNumbers);
      }
    };

    if (visible && !isFetched) {
      fetchWorkTask();
      setIsFetched(true);
    }
  }, [visible, workTaskId, getWorkTaskById, isFetched]);

  const handleAddProducts = () => {
    let numbersArray = articleNumbers.split("\n").map(num => num.trim());
    numbersArray = numbersArray.filter(num => num !== "");
    onAddProducts(numbersArray);
    setArticleNumbers("");
    onClose();
    setIsFetched(false);
  };

  const onCancel = () => {
    setArticleNumbers("");
    onClose();
    setIsFetched(false);
  };

  return (
    <Modal
    title="Lägg till produkter"
    open={visible}
    onOk={handleAddProducts}
    onCancel={onCancel}
  >
    <div style={{ marginBottom: 10, color: 'gray' }}>
      Ange ett artikelnummer per rad.
    </div>
    <Input.TextArea
      rows={7}
      value={articleNumbers}
      onChange={(e) => setArticleNumbers(e.target.value.replace(/,/g, "\n"))}
      placeholder="Klistra in artikelnummer, separerade med radbrytningar"
    />
    <div style={{ marginTop: 10 }}>
      <Button
        type="dashed"
      onClick={() => {
        const newArticle = prompt("Ange artikelnummer:");
        if (newArticle) {
          setArticleNumbers((prev) => prev ? `${prev}\n${newArticle}` : newArticle);
        }
      }}
    >
      Lägg till artikelnummer
    </Button>
    </div>
  </Modal>
  );
};

export default AddWorkTaskProductsModal;