import FullScreenLoader from "components/common/loadIndicators/fullScreenLoader";
import useDataGridStore from "stores/dataGridStore";

const SaveTableDataLoader = () => {
  const { updateDataSourceIsLoading } = useDataGridStore();
  return (
    <FullScreenLoader loading={updateDataSourceIsLoading} message={`Sparar tabelldata`}/>
  );
}

export default SaveTableDataLoader;