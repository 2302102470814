import { Button, message, Tag, Tooltip, Modal, Input } from "antd";
import React, { useState } from "react";
import {
  CircleCheck,
  ClipboardPlusIcon,
  ListCheck,
  Pencil,
  FileCheck,
  FileBox
} from "lucide-react";
import {
  ColumnConfig,
  DEFAULT_LIST_COL_HEIGHT_PX,
  SimpleListView,
} from "components/listViews/simpleListView";
import useWorkTaskOpenApi from "apiClients/useWorkTaskOpenApiClient";
import { WorkTask } from "interfaces/apiTypes";
import { FilterOperatorEnum, LogicalOperatorEnum, SortOrderEnum } from "interfaces/baseTypes";
import useWorkTaskStore from "stores/workTaskStore";
import WorkTaskSectionHeader from "./header/sectionHeader";
import { defaultSectionHeaderIconStyle } from "../styles/workTaskStyles";
import InitialsWithTooltip from "components/common/icons/initialsWithTooltip";
import { WORK_TASKS_QUERY_KEY } from "utility/constants";
import { useQueryClient } from "@tanstack/react-query";
import { formatDate } from "utility/helper";
import AddWorkTaskProductsModal from "../modals/addWorkTaskProductsModal";

const columns: ColumnConfig<WorkTask>[] = [
  {
    header: "Namn",
    accessorKey: "name",
    cell: ({ row }) => <>{row.original.name}</>,
    size: 100,
    sort: true,
  },
  {
    header: "Typ",
    accessorKey: "type",
    cell: ({ row }) => (
      <Tag color={row.original.type === 0 ? "cyan" : "orange"}>
        {row.original.type === 0 ? "Produkt" : "Prisjämförelse"}
      </Tag>
    ),
    size: 50,
    sort: true,
  },
  {
    header: "Status",
    accessorKey: "status",
    cell: ({ row }) => (
      <Tag
        color={
          row.original.status === 0
            ? "blue"
            : row.original.status === 1
            ? "orange"
            : "green"
        }
      >
        {row.original.status === 0
          ? "Inte påbörjad"
          : row.original.status === 1
          ? "Pågående"
          : "Avslutad"}
      </Tag>
    ),
    size: 50,
    sort: true,
  },
  {
    header: "Skapad av",
    accessorKey: "createdBy",
    cell: ({ row }) => (
      <InitialsWithTooltip name={row.original.createdBy?.email ?? ""} />
    ),
    size: 50,
    sort: true,
  },
  {
    header: "Tilldelad",
    accessorKey: "assignedTo",
    cell: ({ row }) => (
      <InitialsWithTooltip
        name={row.original.assignedTo?.email ?? ""}
        backgroundColor={
          !row.original.assignedTo?.email ? "#E1E8ED" : "#52c41a"
        }
        textColor={
          !row.original.assignedTo?.email ? "rgba(0, 0, 0, 0.88)" : "white"
        }
      />
    ),
    size: 50,
    sort: true,
  },
  {
    header: "Beskrivning",
    accessorKey: "description",
    cell: ({ row }) => <>{row.original.description}</>,
    size: 300,
    visible: false,
  },
  {
    header: "Skapad",
    accessorKey: "createdAt",
    cell: ({ row }) => (
      <span>{formatDate(row.original.createdAt) ?? "N/A"}</span>
    ),
    size: 100,
    sort: true,
  },
];

const WorkTaskLists: React.FC<{ sectionHeaderTitle?: string }> = ({
  sectionHeaderTitle,
}) => {
  const { getWorkTasks, patchWorkTask, addProductsToWorkTask, getWorkTaskById } = useWorkTaskOpenApi();
  const queryClient = useQueryClient();
  const {
    setCreateTaskIsOpen,
    setUpdateTaskIsOpen,
    setSelectedWorkTask,
    selectedWorkTask,
  } = useWorkTaskStore();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const onWorkTaskFinished = async () => {
    const status = await patchWorkTask(selectedWorkTask?.id ?? 0, {
      values: [{ propertyPath: "status", value: 2 }]
    });
    if (status) {
      message.success("Arbetslistan har markerats som avslutad");
    }
    queryClient.invalidateQueries({ queryKey: [WORK_TASKS_QUERY_KEY] });
  }

  const handleAddProducts = async (articleNumbers: string[]) => {
    console.log("Adding products:", articleNumbers);
    const status = await addProductsToWorkTask(selectedWorkTask?.id ?? 0, articleNumbers);
    if (status) {
      message.success("Produkterna har lagts till i arbetslistan");
      patchWorkTask(selectedWorkTask?.id ?? 0, {
        values: [{ propertyPath: "itemCount", value: articleNumbers.length }]
      });
    }
    setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [WORK_TASKS_QUERY_KEY] });
        if(selectedWorkTask != null){
            handleOnWorkTaskChange(selectedWorkTask);
        }
    }, 200);
  };

  const handleOnWorkTaskChange = async (item: WorkTask) => {
    const extendedWorkTask = await getWorkTaskById(item.id);
    setSelectedWorkTask(extendedWorkTask?.item ?? item);
  }

  return (
    <>
      <AddWorkTaskProductsModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onAddProducts={handleAddProducts}
        workTaskId={selectedWorkTask?.id}
      />
      <div style={{ position: "sticky", top: 0, zIndex: 11 }}>
        <WorkTaskSectionHeader
          title={sectionHeaderTitle ?? ""}
          subText={
            selectedWorkTask?.id
              ? `${selectedWorkTask?.name ?? ""} (${
                  selectedWorkTask?.id
                })`
              : "Ingen lista vald"
          }
          icon={<ListCheck style={defaultSectionHeaderIconStyle} />}
          showSeparatorLine={true}
          renderActionElement={() => (
            <>
              <div style={{ position: 'relative' }}>
                <Tooltip title="Lägg till produkter">
                  <Button
                    disabled={!selectedWorkTask?.id}
                    type="primary"
                    onClick={() => setIsModalVisible(true)}
                  >
                    <FileBox />
                  </Button>
                </Tooltip>
                {selectedWorkTask?.itemCount !== undefined && (
                  <span style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    color: 'white',
                    padding: '2px 6px',
                    fontSize: '12px',
                    fontWeight: 'bold',
                  }}>
                    {selectedWorkTask.itemCount}
                  </span>
                )}
              </div>
              <Tooltip title="Markera arbetslista som avslutad">
                <Button
                  disabled={
                    !selectedWorkTask?.id ||
                    selectedWorkTask?.status === 2
                  }
                  type="primary"
                  onClick={onWorkTaskFinished}
                >
                  <CircleCheck />
                </Button>
              </Tooltip>
              <Tooltip title="Redigera arbetslista">
                <Button
                  disabled={!selectedWorkTask?.id}
                  type="primary"
                  onClick={() => {
                    setUpdateTaskIsOpen(true);
                  }}
                >
                  <Pencil />
                </Button>
              </Tooltip>
              <Tooltip title="Skapa ny arbetslista">
                <Button
                  type="primary"
                  onClick={() => {
                    setCreateTaskIsOpen(true);
                  }}
                >
                  <ClipboardPlusIcon />
                </Button>
              </Tooltip>
            </>
          )}
        />
      </div>

      <SimpleListView<WorkTask>
        queryKey={WORK_TASKS_QUERY_KEY}
        filterGroup={{
          operator: LogicalOperatorEnum.AND,
          value: [
            {
              fieldName: "type",
              operator: FilterOperatorEnum.EQUALS,
              value: "0",
            }
          ]
        }}
        defaultSorting={{
          fieldName: "createdAt",
          sortOrder: SortOrderEnum.DESC
        }}
        resizableColumns={false}
        selectable={false}
        onRowClick={handleOnWorkTaskChange}
        fetchItems={async (
          page,
          pageSize,
          sortOrder,
          orderByField,
          filterString
        ) =>
          await getWorkTasks({
            page: page,
            pageSize: pageSize,
            order: sortOrder,
            orderByField: orderByField,
            filters: filterString,
          })
        }
        columns={columns}
        renderHeader={() => {
          return <React.Fragment></React.Fragment>;
        }}
        listLoadingHeightPx={DEFAULT_LIST_COL_HEIGHT_PX * 5}
      />
    </>
  );
};

export default WorkTaskLists;
