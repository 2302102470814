import { useEffect, useState } from "react";
import useDataGridStore from "stores/dataGridStore";
import LockedMenuOverlay from "../lockedFilterComps/lockedMenuOverlay";
import WorkTaskSelect from "components/common/select/workTaskSelect";
import LockedMenuModal from "../lockedFilterComps/lockedMenuModal";
import { WorkTask } from "interfaces/apiTypes";
import { FilterGroup, FilterOperatorEnum, LogicalOperatorEnum } from "interfaces/baseTypes";
import { parseFiltersToString, parseStringToFilters } from "utility/helper";

const SelectWorkTaskFilter = () => {
  const { setDataGridFilterStr, setActiveFilterSelector, activeFilterSelector, updateStack, dataSourceIsLoading } =
    useDataGridStore();
  const [lockedMenu, setLockedMenu] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [defaultWorkTaskId, setDefaultWorkTaskId] = useState<
    number | undefined
  >(undefined);
  const [resetKey, setResetKey] = useState(0);

  useEffect(() => {
    if (
      activeFilterSelector !== "workTaskSelector" &&
      activeFilterSelector !== undefined
    ) {
      setResetKey((prev) => prev + 1);
    }
  }, [activeFilterSelector]);

  useEffect(() => {
    setLockedMenu((prevLockedMenu) => {
      const currentUpdateStackLength = updateStack ? updateStack?.length : 0;
      if (prevLockedMenu && currentUpdateStackLength === 0) {
        return false; // Transition from non-zero to zero
      }
      if (!prevLockedMenu && currentUpdateStackLength > 0) {
        return true; // Transition from zero to non-zero
      }
      // Avoid state update triggering rerenders, return previous state
      return prevLockedMenu;
    });
  }, [updateStack]);

  const handleUnlockClick = () => {
    setIsModalVisible(true);
  };

  const confirmUnlock = () => {
    setLockedMenu(false);
    setIsModalVisible(false);
  };

  const cancelUnlock = () => {
    setIsModalVisible(false);
  };

  const handleWorkTaskChange = (value?: WorkTask) => {
    setDataGridFilterStr(buildProductFilterString(value));
    setActiveFilterSelector("workTaskSelector");
  };

  const buildProductFilterString = (workTask?: WorkTask) : string => {
    if (!workTask) return "";

    const filterGroup: FilterGroup = {
      operator: LogicalOperatorEnum.AND,
      value: [],
    };
    
    if(workTask.items && workTask.items.length > 0){
      const productStaticFilterGroup: FilterGroup = {
        operator: LogicalOperatorEnum.AND,
        value: [],
      };
      const productIds = workTask.items.map(item => item?.productId ?? 0);
      productStaticFilterGroup.value.push({
        fieldName: "id",
        operator: FilterOperatorEnum.IN,
        value: productIds.join(","),
      });
      filterGroup.value.push(productStaticFilterGroup);
    }

    if(workTask.queryString){
      const dynamicFilters = parseStringToFilters(workTask.queryString, workTask.queryType === 0 ? LogicalOperatorEnum.AND : LogicalOperatorEnum.OR);
      filterGroup.value.push(dynamicFilters);
    }

    return parseFiltersToString(filterGroup);
  };

  return (
    <div style={{ position: "relative" }}>
      <LockedMenuOverlay
        handleUnlockClick={handleUnlockClick}
        lockedMenu={lockedMenu}
      />
      <WorkTaskSelect
        key={resetKey} // Reset key to force to reset the component and clear the value
        defaultWorkTaskId={defaultWorkTaskId}
        workTaskType={0}
        disabled={dataSourceIsLoading || lockedMenu}
        onSelectedWorkTask={handleWorkTaskChange}
      />
      <LockedMenuModal
        isModalVisible={isModalVisible}
        confirmUnlock={confirmUnlock}
        cancelUnlock={cancelUnlock}
      />
    </div>
  );
};

export default SelectWorkTaskFilter;