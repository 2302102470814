import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  VisibilityState,
  getSortedRowModel,
  SortingState,
  Row,
  Table,
  RowSelectionState,
} from '@tanstack/react-table';
import { ResponseWrapperMany } from 'interfaces/apiTypes';
import styles from './simpleListView.module.css';
import { Base, FilterGroup, SortOrderEnum } from 'interfaces/baseTypes';
import { Button, Checkbox, Drawer, Pagination, Spin, Tooltip } from 'antd';
import { ArrowUpDown, ArrowUp, ArrowDown, Columns2, Ellipsis } from 'lucide-react';
import { parseFiltersToString } from 'utility/helper';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

export const DEFAULT_LIST_COL_HEIGHT_PX = 48;

export interface ColumnConfig<T> {
  header: string;
  accessorKey: string;
  cell: (props: { row: { original: T } }) => JSX.Element;
  size?: number;
  sort?: boolean;
  visible?: boolean;
}

export interface DefaultSortingField {
  fieldName: string;
  sortOrder: SortOrderEnum;
}

interface SimpleListViewProps<T extends Base> {
    columns: ColumnConfig<T>[];
    fetchItems: (page: number, pageSize: number, order: SortOrderEnum, orderByField: string, filters?: string) => Promise<ResponseWrapperMany<T> | undefined>;
    queryKey: string;
    equalColumnWidths?: boolean;
    resizableColumns?: boolean;
    onSelectionChange?: (selectedItems: T[]) => void;
    selectable?: boolean;
    onRowClick?: (item: T) => void;
    renderHeader?: (totalCount: number) => JSX.Element;
    filterGroup?: FilterGroup;
    onCountChange?: (count: number) => void; // Add this line
    listLoadingHeightPx?: number;
    defaultSorting?: DefaultSortingField;
}

export const SimpleListView = <T extends Base>({
    columns,
    fetchItems,
    queryKey,
    equalColumnWidths = false,
    resizableColumns = false,
    onSelectionChange,
    selectable = false,
    onRowClick,
    renderHeader,
    filterGroup,
    onCountChange,
    listLoadingHeightPx = DEFAULT_LIST_COL_HEIGHT_PX * 2,
    defaultSorting,
}: SimpleListViewProps<T>) => {
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalCount, setTotalCount] = useState(10);
    const [sorting, setSorting] = useState<SortingState>([]);
    const [forceLoader, setForceLoader] = useState<boolean>(false);

    const [selectedItems, setSelectedItems] = useState<T[]>([]);
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const [filterString, setFilterString] = useState<string>(''); 
    const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null); // Add this line

    useEffect(() => {
        if (!filterGroup) {
            return;
        }
        const newFilterString = parseFiltersToString(filterGroup, true);
        setFilterString(newFilterString);
    }, [filterGroup]); 

    useEffect(() => {
        const savedVisibility = localStorage.getItem(`${queryKey}-columnVisibility`);
        if (savedVisibility) {
            setColumnVisibility(JSON.parse(savedVisibility));
        } else {
            // Set default visibility based on column's visible property
            const defaultVisibility: VisibilityState = {};
            columns.forEach(column => {
                defaultVisibility[column.accessorKey] = column.visible !== false; // Default to true if not set
            });
            setColumnVisibility(defaultVisibility);
        }
    }, [queryKey, columns]);

    useEffect(() => {
        if (columnVisibility && Object.keys(columnVisibility).length > 0 && queryKey) {
            localStorage.setItem(`${queryKey}-columnVisibility`, JSON.stringify(columnVisibility));
        }
    }, [columnVisibility, queryKey]);

    useEffect(() => {
        // Read page and pageSize from URL on component mount
        const params = new URLSearchParams(window.location.search);
        const page = params.get('page');
        const pageSize = params.get('pageSize');

        if (page) {
            setCurrentPage(Number(page) - 1); // Adjust for zero-based index
        }
        if (pageSize) {
            setPageSize(Number(pageSize));
        }
    }, []); // Run only on component mount

    const { data, isLoading, error } = useQuery<ResponseWrapperMany<T>, Error>({
        queryKey: [queryKey, currentPage, pageSize, sorting, filterString],
        queryFn: async () => {
            setForceLoader(true);
            const defaultSortOrder = defaultSorting?.sortOrder || SortOrderEnum.ASC;
            const defaultOrderField = defaultSorting?.fieldName || "id"
            const result = await fetchItems(currentPage, pageSize, sorting.length > 0 ? sorting[0].desc ? SortOrderEnum.DESC : SortOrderEnum.ASC : defaultSortOrder,  sorting.length > 0 ? sorting[0].id : defaultOrderField, filterString);
            if (!result) {
                throw new Error('No data returned');
            }
            setTotalCount(result.totalCount);
            setTimeout(() => {
                setForceLoader(false);
            }, 200);
            // Call onCountChange if provided
            if (onCountChange) {
                onCountChange(result.totalCount);
            }

            //set selected rows based on if data is in selectedItems
            const newRowSelection = {} as RowSelectionState;
            result.items.forEach((item, index) => {
                newRowSelection[index] = selectedItems.some(selectedItem => selectedItem.id === item.id);
            });
            setRowSelection(newRowSelection);
            return result;
        }
    });

    const handleRowSelectionChange = useCallback((newSelection: Record<string, boolean>) => {
        setRowSelection(newSelection);
        const updatedSelectedItems = [...selectedItems];

        Object.entries(newSelection).forEach(([index, isSelected]) => {
            const item = data?.items[Number(index)];
            if (isSelected && item && !updatedSelectedItems.some(selectedItem => selectedItem.id === item.id)) {
                updatedSelectedItems.push(item);
            } else if (!isSelected) {
                const itemIndex = updatedSelectedItems.findIndex(selectedItem => selectedItem.id === item?.id);
                if (itemIndex !== -1) {
                    updatedSelectedItems.splice(itemIndex, 1);
                }
            }
        });

        setSelectedItems(updatedSelectedItems || []);

        if (onSelectionChange) {
            onSelectionChange(updatedSelectedItems || []);
        }
    }, [data, onSelectionChange]);

    const selectColumn = useMemo(() => ({
        id: 'checkbox',
        header: ({ table }: { table: Table<any> }) => (
            <Checkbox
                checked={Object.keys(rowSelection).length > 0 && Object.values(rowSelection).every(Boolean)}
                indeterminate={Object.keys(rowSelection).length > 0 && Object.values(rowSelection).some(Boolean) && !Object.values(rowSelection).every(Boolean)}
                onChange={(e) => {
                    const newSelection: Record<number, boolean> = {};
                    data?.items.forEach((_, index) => {
                        newSelection[index] = e.target.checked;
                    });
                    handleRowSelectionChange(newSelection);
                }}
            />
        ),
        cell: ({ row }: { row: Row<any> }) => (
            <Checkbox
                checked={rowSelection[row.index]}
                onChange={(e) => {
                    e.stopPropagation(); // Prevent row click event
                    handleRowSelectionChange({
                        ...rowSelection,
                        [row.index]: e.target.checked,
                    });
                }}
            />
        ),
        enableSorting: false,
        enableResizing: false,
        size: 40,
    }), [data, rowSelection, handleRowSelectionChange]);

    const tableColumns = selectable
    ? [selectColumn, ...columns.map(column => ({
        ...column,
        size: column.size || 150,
        enableResizing: resizableColumns,
        enableSorting: column.sort || false,
      }))]
    : columns.map(column => ({
        ...column,
        size: column.size || 150,
        enableResizing: resizableColumns,
        enableSorting: column.sort || false,
    }));

    const table = useReactTable({
        data: data?.items || [],
        columns: tableColumns,
        state: {
            columnVisibility,
            sorting,
            rowSelection,
        },
        onColumnVisibilityChange: setColumnVisibility,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        columnResizeMode: 'onChange',
        enableColumnResizing: resizableColumns,
        enableRowSelection: selectable,
    });

    const totalPages = Math.ceil(totalCount / (data?.items.length || 1)); // Calculate total pages

    const handlePageChange = (newPage: number, newPageSize: number) => {
        if (newPage >= 0 && newPage <= totalPages) {
            setCurrentPage(newPage - 1);
        }
        if (newPageSize !== pageSize) {
            setPageSize(newPageSize);
        }

        // Update URL with new page and pageSize
        const params = new URLSearchParams(window.location.search);
        params.set('page', (newPage).toString());
        params.set('pageSize', newPageSize.toString());
        window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
    };

    const [isDragging, setIsDragging] = useState(false);
    const dragStartPos = useRef<{ x: number; y: number } | null>(null);

    const handleMouseDown = useCallback((e: React.MouseEvent) => {
        dragStartPos.current = { x: e.clientX, y: e.clientY };
    }, []);

    const handleMouseMove = useCallback((e: React.MouseEvent) => {
        if (dragStartPos.current) {
            const dx = Math.abs(e.clientX - dragStartPos.current.x);
            const dy = Math.abs(e.clientY - dragStartPos.current.y);
            if (dx > 5 || dy > 5) {
                setIsDragging(true);
            }
        }
    }, []);

    const handleRowClick = useCallback((event: React.MouseEvent, target: EventTarget & HTMLTableRowElement) => {
        if (
            event.target instanceof HTMLInputElement && event.target.type === 'checkbox' ||
            event.target instanceof HTMLLabelElement ||
            (event.target instanceof HTMLElement && event.target.className.startsWith('ant-image'))
        ) {
            return;
        }

        if (onRowClick) {
            const rowIndex = parseInt(target.dataset.rowIndex || '0', 10);
            const item = data?.items[rowIndex];
            if (item) {
                onRowClick(item);
                setSelectedRowIndex(rowIndex);
            }
        }
    }, [onRowClick, data]);

    const handleMouseUp = useCallback((e: React.MouseEvent) => {
        if (!isDragging) {
            handleRowClick(e, e.currentTarget as EventTarget & HTMLTableRowElement);
        }
        setIsDragging(false);
        dragStartPos.current = null;
    }, [isDragging, handleRowClick]);

    return (
      <div className={styles.container}>
        {error && <div className={styles.error}>{error.message}</div>}
        <div className={styles.tableControls}>
          {renderHeader && renderHeader(totalCount)}
        </div>
        <div className={styles.tableWrapper}>
          <table
            className={`${styles.table} ${
              equalColumnWidths ? styles.equalColumns : styles.defaultColumns
            }`}
          >
            <thead style={{ position: "relative" }}>
              <ColumnSelectorDrawerButton table={table} />
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className={styles.headerRow}>
                  {headerGroup.headers.map((header, index) => (
                    <th
                      key={header.id}
                      className={styles.headerCell}
                      style={{
                        width: header.getSize(),
                        position: "relative",
                        cursor: header.column.getCanSort()
                          ? "pointer"
                          : "default",
                      }}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <div className={styles.headerContent}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                        {header.column.getCanSort() && (
                          <span className={styles.sortIcon}>
                            {header.column.getIsSorted() === "asc" ? (
                              <ArrowUp size={16} />
                            ) : header.column.getIsSorted() === "desc" ? (
                              <ArrowDown size={16} />
                            ) : (
                              <ArrowUpDown size={16} />
                            )}
                          </span>
                        )}
                      </div>
                      {resizableColumns && (
                        <div
                          onMouseDown={header.getResizeHandler()}
                          onTouchStart={header.getResizeHandler()}
                          className={`${styles.resizer} ${
                            header.column.getIsResizing()
                              ? styles.isResizing
                              : ""
                          }`}
                        />
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <>
              {(!isLoading && !forceLoader) && data && data.items.length > 0 ? (
                <tbody>
                  {table.getRowModel().rows.map((row, index) => (
                    <tr
                      key={row.id}
                      className={`${styles.row} ${
                        selectedRowIndex === index ? styles.selectedRow : ""
                      }`}
                      onMouseDown={handleMouseDown}
                      onMouseMove={handleMouseMove}
                      onMouseUp={handleMouseUp}
                      data-row-index={index}
                      style={{ cursor: onRowClick ? "pointer" : "default" }}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          className={styles.cell}
                          style={{
                            width: cell.column.getSize(),
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody
                  className={styles.noData}
                  style={{ height: `${listLoadingHeightPx}px`, position: "relative" }}
                >
                  {(isLoading || forceLoader) ? (
                    <Spin
                      tip={"message"}
                      size="large"
                      spinning={true}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "#808080",
                      }}
                    >
                      Ingen data hittades
                    </div>
                  )}
                </tbody>
              )}
            </>

            <tfoot>
              <tr>
                <td colSpan={table.getAllLeafColumns().length}>
                  <div className={styles.footer}>
                    <div>
                      Antal element: <strong>{totalCount}</strong>
                    </div>
                    <div>
                      <Pagination
                        current={currentPage + 1}
                        pageSize={pageSize}
                        total={totalCount}
                        onChange={handlePageChange}
                        showSizeChanger
                        pageSizeOptions={[10, 20, 50, 100]}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    );
};

const ColumnSelectorDrawerButton = <T extends object>({table}: {table: Table<T>}) => {
    const [visible, setVisible] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const { t } = useTranslation();
    
    const showDrawer = () => {
        setVisible(true);
        setShowOptions(false);
    };

    const onClose = () => {
        setVisible(false);
    };

    const setShowOptionsDebounced = debounce(() => {
      setShowOptions(true);
  }, 200);

    const handleOptionsMouseEnter = () => {
        setShowOptions(true);
    };

    const handleOptionsMouseLeave = () => {
        setShowOptions(false);
    };

    return (
      <>
        <Button
            onClick={setShowOptionsDebounced}
            className={styles.columnSelectorButton}
            type="text"
            icon={<Ellipsis size={16} color="rgba(0, 0, 0, 0.88)" />}
        />
        <div style={{ position: 'absolute', right: '0px', top: '25px', zIndex: 1 }}>
        {showOptions && (
          <div 
            className={`${styles.optionsList} optionsList-show`}
            onMouseEnter={handleOptionsMouseEnter}
            onMouseLeave={handleOptionsMouseLeave}
          >
            {table.getAllLeafColumns().map((column) => (
              <div key={column.id} onClick={column.getToggleVisibilityHandler()} style={{ display: 'flex', alignItems: 'center' }}>
                {column.getIsVisible() ? (
                  <span style={{ marginRight: '0.5rem' }}>👁️</span>
                ) : (
                  <span style={{ marginRight: '0.5rem' }}>👁️‍🗨️</span>
                )}
                {t(column?.id) ?? column?.id}
              </div>
            ))}
          </div>
        )}
      </div>
        <Drawer
          title="Välj aktiva kolumner"
          placement="right"
          onClose={onClose}
          open={visible}
        >
          <div style={{ padding: "0rem 0.40rem" }}>
            {table.getAllLeafColumns().map((column) => (
              <label key={column.id} className={styles.columnToggle}>
                <Checkbox
                  checked={column.getIsVisible()}
                  onChange={column.getToggleVisibilityHandler()}
                />
                <span style={{ marginLeft: "0.5rem", fontSize: "16px" }}>
                  {t(column?.id?.toLowerCase()) ?? column?.id}
                </span>
              </label>
            ))}
          </div>
        </Drawer>
        </>
    );
}
