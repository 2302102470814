import { WorkTask } from "interfaces/apiTypes";
import { FilterGroup, LogicalOperatorEnum } from "interfaces/baseTypes";
import { create } from "zustand";
import { FilterItem } from "interfaces/baseTypes";

type WorkTaskStore = {
  // Create task modal
  createTaskIsOpen: boolean;
  setCreateTaskIsOpen: (isOpen: boolean) => void;

  // Update task modal
  updateTaskIsOpen: boolean;
  setUpdateTaskIsOpen: (isOpen: boolean) => void;

  // Loading
  globalLoading: { isLoading: boolean; message: string };
  setGlobalLoading: (isLoading: boolean, message?: string) => void;

  // Reset
  resetState: () => void;

  // Selected work task
  selectedWorkTask: WorkTask | null; // Changed from selectedTaskList
  setSelectedWorkTask: (data: WorkTask | null) => void; // Changed from setSelectedTaskList

  // Checked work tasks
  checkedWorkTasks: WorkTask[];
  setCheckedWorkTasks: (tasks: WorkTask[]) => void; // Changed from setCheckedTaskLists

  // Add filter items to the store
  currentFilterGroup: FilterGroup | FilterItem; // Changed from currentFilterItems
  setCurrentFilterGroup: (group: FilterGroup | FilterItem) => void; // Changed from setCurrentFilterItems
};

// Initial state definition
const initialState = {
  createTaskIsOpen: false,
  updateTaskIsOpen: false,
  globalLoading: {
    isLoading: false,
    message: "",
  },
  selectedWorkTask: null, // Changed from selectedTaskList
  checkedWorkTasks: [], // Changed from checkedTaskLists
  currentFilterGroup: {
    operator: LogicalOperatorEnum.AND,
    value: [],
  },
};

const useWorkTaskStore = create<WorkTaskStore>((set) => ({
  ...initialState,

  // Create task modal
  setCreateTaskIsOpen: (isOpen) => set({ createTaskIsOpen: isOpen }),
  setUpdateTaskIsOpen: (isOpen) => set({ updateTaskIsOpen: isOpen }),
  // Loading
  setGlobalLoading: (isLoading, message = "") => {
    set({
      globalLoading: {
        isLoading,
        message,
      },
    });
  },

  // Reset
  resetState: () => set(initialState),

  // Selected work task
  setSelectedWorkTask: (data) => set({ selectedWorkTask: data }), // Changed from setSelectedTaskList

  // Checked work tasks
  setCheckedWorkTasks: (tasks) => set({ checkedWorkTasks: tasks }), // Changed from setCheckedTaskLists

  // Add filter items setter
  setCurrentFilterGroup: (group) => set({ currentFilterGroup: group }), // Changed from setCurrentFilterItems
}));

export default useWorkTaskStore;
