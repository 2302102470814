import TextArea from "antd/es/input/TextArea";
import DataGridModal from "components/modals/dataGridModal";

const TextModalGrid = ({ editorProps }: { editorProps: any }) => {
  const onClose = () => {
    editorProps.cell.stopEdit();
  };
  const onSave = async () => {
    await editorProps.onComplete();
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    editorProps?.onChange && editorProps?.onChange(e.target.value);
  };

  return (
    <DataGridModal
      isOpen={editorProps?.cell?.isInEdit()}
      onClose={onClose}
      onSave={onSave}
      title={editorProps?.cellProps?.header}
    >
      <TextArea
        rows={30}
        spellCheck="true"
        defaultValue={editorProps?.value}
        onChange={handleOnChange}
      />
    </DataGridModal>
  );
};

export default TextModalGrid;
