import useDataGridStore from "stores/dataGridStore";

const useHandleStackUndo = () => {
  const { dataSource, setDataSource, setUpdateStack, updateStack } =
    useDataGridStore();

  const handleUndo = (latestChangedItem: any) => {
    const data = [...dataSource];
    const itemId = latestChangedItem.cell.split(",")[0];
    const indexToUpdate = data.findIndex((data) => data.id == itemId);
    const columnProp = latestChangedItem.cell.split(",")[1];
    data[indexToUpdate][columnProp] = latestChangedItem.value;
    setDataSource(data);
    setUpdateStack((prevStack) => prevStack.slice(0, -1));
  };

  const handleUndoStackUpdate = () => {
    if (updateStack?.length === 0) return;
    const batchRevisionId = updateStack[updateStack.length - 1]?.revisionId;
    const batchUpdateStack = updateStack.filter(
      (update) => update.revisionId === batchRevisionId
    );
    for (const batchUpdateItem of batchUpdateStack) {
      handleUndo(batchUpdateItem);
    }
  };

  return { handleUndoStackUpdate };
};

export default useHandleStackUndo;
