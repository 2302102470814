import { IBrand, ICategory, IMetadata, IProductGroup } from 'interfaces';
import { EntityMetaData } from 'interfaces/apiTypes';
import { create } from 'zustand';

type MetadataStore = {
    metadata: IMetadata | undefined;
    productMetadata: EntityMetaData | undefined;
    isloading: boolean;
    setIsLoading: (isLoading: boolean) => void;
    setMetadata: (metadata: IMetadata | undefined) => void;
    setProductMetadata: (metadata: EntityMetaData | undefined) => void;
    getCategoryById: (id: number) => ICategory | undefined;
    getBrandById: (id: number) => IBrand | undefined;
    getProductGroupById: (id: number) => IProductGroup | undefined;
    getBrands: () => IBrand[];
    getProductGroups: () => IProductGroup[];
};

const useMetadataStore = create<MetadataStore>((set) => ({
    metadata: undefined,
    productMetadata: undefined,
    isloading: true,
    setIsLoading: (isLoading) => set({ isloading: isLoading }),
    setMetadata: (metadata) => set({ metadata, isloading: false }),
    getCategoryById: (id) => {
        const metadata = useMetadataStore.getState().metadata as IMetadata;
        if (!id) return undefined;
        return (metadata?.categories || []).find((category) => category.id === id);
    },
    getBrandById: (id) => {
        const metadata = useMetadataStore.getState().metadata as IMetadata;
        if (!id) return undefined;
        return (metadata?.brands || []).find((brand) => brand.id === id);
    },
    getProductGroupById: (id) => {
        const metadata = useMetadataStore.getState().metadata as IMetadata;
        if (!id) return undefined;
        return (metadata?.productGroups || []).find((productGroup) => productGroup.id === id);
    },
    getBrands: () => {
        const metadata = useMetadataStore.getState().metadata as IMetadata;
        return metadata?.brands || [];
    },
    getProductGroups: () => {
        const metadata = useMetadataStore.getState().metadata as IMetadata;
        return metadata?.productGroups || [];
    },
    setProductMetadata: (metadata) => set({ productMetadata: metadata }),
}));

export default useMetadataStore;