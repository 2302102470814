import React, { useState, useEffect } from "react";
import { Input, Form, Button, Alert, Collapse } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { CircleAlert } from "lucide-react";
import styled from "@emotion/styled";
import { OpenNotificationParams } from "@refinedev/core";
import usePriceTrackerOpenApi from "apiClients/usePriceTrackerOpenApiClient";
import WorkTaskSelect from "components/common/select/workTaskSelect";
import { useLocaleStore } from "stores/localeStore";

const EditorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 0rem;
  gap: 10px;
`;

const EditorWrapper = styled.div`
  flex: 1;
`;

const NetworkError = styled.div`
  border-radius: 8px;
  border: 1px solid rgb(255, 0, 0);
  margin: 1rem 0px;
  padding: 8px;
  background-color: #ffe6e6;
  color: rgb(255, 0, 0);
  .error-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .error-content-message {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const ButtonBar = styled.div`
  display: flex;
  justify-content: flex-end; /* Align buttons to the right */
  gap: 8px; /* Horizontal gap between buttons */
  background: transparent;
  margin-top: 12px;
  padding: 0;
  border-top: none;
  border-radius: 0;
`;

const ErrorMessageText = styled.span`
  font-size: 1rem;
  font-weight: 500;
`;

const CollapsiblePanel = styled(Collapse)<{ disabled?: boolean }>`
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  .ant-collapse-content-box {
    padding: 8px 8px !important;
    max-height: 145px;
    overflow: auto;
  }
  .ant-alert-warning {
    margin-bottom: 3px !important;
  }
`;

const { TextArea } = Input;
const { Panel } = Collapse;

interface CreateNewPriceComparisonsProps {
  onSuccess?: () => void;
  openSuccessNotification?: ((params: OpenNotificationParams) => void) | undefined;
  openErrorNotification?: ((params: OpenNotificationParams) => void) | undefined;
  onCancel?: () => void;
  onError?: () => void;
}

const CreateNewPriceComparisons = ({
  onSuccess,
  openSuccessNotification,
  openErrorNotification,
  onCancel,
  onError,
}: CreateNewPriceComparisonsProps) => {
  const [articleNumbers, setArticleNumbers] = useState<string>("");
  const [searchKeys, setSearchKeys] = useState<string>("");
  const [lineCountError, setLineCountError] = useState("");
  const [workTaskId, setWorkTaskId] = useState<number | undefined>(undefined);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const { priceTrackerBatchImport, loading, errorMessage } = usePriceTrackerOpenApi();
  const [supplierNumbers, setSupplierNumbers] = useState<string>(""); // New state for SupplierNumbers
  const [suppliers, setSuppliers] = useState<string>(""); // New state for Suppliers
  const { activeLanguage } = useLocaleStore();

  // Handle the change event for the article numbers
  const handleArticleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setArticleNumbers(value);
    validateInput(value, searchKeys, suppliers, supplierNumbers); // Update validation
  };

  // Handle the change event for the search keys
  const handleSearchChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setSearchKeys(value);
    validateInput(articleNumbers, value, suppliers, supplierNumbers); // Update validation
  };

  // Handle the change event for the supplier numbers
  const handleSupplierChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setSuppliers(value);
    validateInput(articleNumbers, searchKeys, value, supplierNumbers); // Update validation
  };

  // Handle the change event for the supplier numbers
  const handleSupplierNumberChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setSupplierNumbers(value);
    validateInput(articleNumbers, searchKeys, suppliers, value); // Update validation
  };

  // Update the validation function to include suppliers and supplier numbers
  const validateInput = (articleNumbers: string, searchKeys: string, suppliers: string, supplierNumbers: string) => {
    const articleLines = articleNumbers.split("\n");
    const searchLines = searchKeys.split("\n");
    const errors: string[] = [];

    // Check for empty lines and non-numeric values in article numbers
    articleLines.forEach((line, index) => {
      if (!line.trim()) {
        errors.push(`Artikelrad ${index + 1} är tom.`);
      } else if (isNaN(Number(line.trim()))) {
        errors.push(`Artikelrad ${index + 1} måste vara ett nummer.`);
      }
    });

    // Check for empty lines and non-numeric values in search keys
    searchLines.forEach((line, index) => {
      if (!line.trim()) {
        errors.push(`Söktermsrad ${index + 1} är tom.`);
      } else if (isNaN(Number(line.trim()))) {
        errors.push(`Söktermsrad ${index + 1} måste vara ett nummer.`);
      }
    });

    // Check for empty lines and non-numeric values in suppliers
    const supplierLines = suppliers.split("\n");
    supplierLines.forEach((line, index) => {
      if (!line.trim()) {
        errors.push(`Leverantör ${index + 1} är tom.`);
      }
    });

    // Check for empty lines and non-numeric values in supplier numbers
    const supplierNumberLines = supplierNumbers.split("\n");
    supplierNumberLines.forEach((line, index) => {
      if (!line.trim()) {
        errors.push(`Leverantörsnummer ${index + 1} är tom.`);
      } else if (isNaN(Number(line.trim()))) {
        errors.push(`Leverantörsnummer ${index + 1} måste vara ett nummer.`);
      }
    });

    // Ensure all fields have the same number of lines
    if (
      articleLines.filter(Boolean).length !== searchLines.filter(Boolean).length ||
      supplierLines.filter(Boolean).length !== supplierNumberLines.filter(Boolean).length
    ) {
      setLineCountError(() => {
        const pluralOrSingularRaderArt =
          articleLines.filter(Boolean).length === 1 ? "rad" : "rader";
        const pluralOrSingularRaderTerm = searchLines.filter(Boolean).length === 1 ? "rad" : "rader";
        return ` Antalet rader i båda fälten måste matcha. Artiklar har ${
          articleLines.filter(Boolean).length
        } ${pluralOrSingularRaderArt}, söktermer har ${
          searchLines.filter(Boolean).length
        } ${pluralOrSingularRaderTerm}.`;
      });
    } else {
      setLineCountError("");
    }

    setValidationErrors(errors);
  };

  const handleSubmit = async () => {
    if (!lineCountError && validationErrors.length === 0) {
      const articleNumbersArr = articleNumbers.split("\n").filter(Boolean);
      const searchStringsArr = searchKeys.split("\n").filter(Boolean);
      const suppliersArr = suppliers.split("\n").filter(Boolean);
      const supplierNumbersArr = supplierNumbers.split("\n").filter(Boolean);

      await priceTrackerBatchImport(articleNumbersArr, searchStringsArr, suppliersArr, supplierNumbersArr, workTaskId || 0, activeLanguage?.id || 1);

      if (!errorMessage) {
        openSuccessNotification?.({
          type: "success",
          message: `Det har startat en ny session för prisjämförelser för ${
            articleNumbersArr?.length
          } ${articleNumbersArr?.length === 1 ? "produkt" : "produkter"}.`,
          description: "Success",
        });
        onSuccess?.(); // Trigger the success callback
      }

      if (errorMessage) {
        openErrorNotification?.({
          type: "error",
          message: "Något gick fel i anropet mot servern.",
          description: "Erorr",
        });
        onError?.();
      }
    }
  };

  // Get icon based on warning presence
  const getValidationIcon = () =>
    validationErrors.length > 0 || lineCountError ? (
      <ExclamationCircleOutlined style={{ color: "rgb(255, 0, 0" }} />
    ) : null;

  return (
    <div>
      <Form layout="vertical" onFinish={handleSubmit}>
        <EditorContainer>
          <EditorWrapper>
            <Form.Item label="Artikelnummer" required>
              <TextArea
                rows={6}
                placeholder="Skriv/lägg in artikelnummer (ett per rad)"
                value={articleNumbers}
                onChange={handleArticleChange}
              />
            </Form.Item>
          </EditorWrapper>

          <EditorWrapper>
            <Form.Item label="Söktermer EAN" required>
              <TextArea
                rows={6}
                placeholder="Skriv/lägg in söktermer (en per rad)"
                value={searchKeys}
                onChange={handleSearchChange}
              />
            </Form.Item>
          </EditorWrapper>

          <EditorWrapper>
            <Form.Item label="Leverantör" required>
              <TextArea
                rows={6}
                placeholder="Skriv/lägg in leverantör (en per rad)"
                value={suppliers}
                onChange={handleSupplierChange}
              />
            </Form.Item>
          </EditorWrapper>

          <EditorWrapper>
            <Form.Item label="Leverantörsnummer" required>
              <TextArea
                rows={6}
                placeholder="Skriv/lägg in leverantörsnummer (ett per rad)"
                value={supplierNumbers}
                onChange={handleSupplierNumberChange}
              />
            </Form.Item>
          </EditorWrapper>
        </EditorContainer>
        <CollapsiblePanel
          bordered={true}
          defaultActiveKey={
            validationErrors.length > 0 || lineCountError ? "1" : ""
          }
          disabled={validationErrors.length === 0 && !lineCountError}
        >
          <Panel
            header="Validerings varningar"
            key="1"
            extra={getValidationIcon()}
          >
            {validationErrors.length > 0 && (
              <div>
                {validationErrors.map((error, index) => (
                  <Alert key={index} message={error} type="warning" showIcon />
                ))}
              </div>
            )}

            {lineCountError && (
              <Alert message={lineCountError} type="warning" />
            )}
          </Panel>
        </CollapsiblePanel>
        {errorMessage && (
          <NetworkError>
            <div className="error-content">
              <div className="error-content-message">
                <CircleAlert
                  size={"1.5rem"}
                  style={{ color: "rgb(255, 0, 0)"}}
                />
                <ErrorMessageText>
                  Något gick fel i anropet mot servern.
                </ErrorMessageText>
              </div>
            </div>
          </NetworkError>
        )}

        <Form.Item label="Arbetslista" required style={{ marginTop: '1rem' }}>
          <WorkTaskSelect onSelectedWorkTask={(value) => setWorkTaskId(value?.id)} workTaskType={1} />
        </Form.Item>

        <ButtonBar>
          <Button
            type="default"
            onClick={() => onCancel?.()}
            disabled={loading}
          >
            Avbryt
          </Button>

          <Button
            loading={loading}
            htmlType="submit" // This makes sure the button submits the form
            type="primary"
            disabled={
              loading ||
              !!lineCountError ||
              validationErrors.length > 0 ||
              articleNumbers === "" ||
              searchKeys === "" ||
              !workTaskId
            }
          >
            Skicka
          </Button>
        </ButtonBar>
      </Form>
    </div>
  );
};

export default CreateNewPriceComparisons;
