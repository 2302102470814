import useDataGridStore, { SaveStackDataResult } from "stores/dataGridStore";
import useHttpClient from "../useHttpClient";
import { useEffect, useRef, useState } from "react";
import { BaseKey } from "@refinedev/core";
import { notification } from "antd";
import { FlexibleIProductUpdatePayload } from "interfaces";
import { enhanceProduct, Product, ResponseWrapperMany } from "interfaces/apiTypes";
import { RelationType } from "utility/helper";
import { useLocaleStore } from "stores/localeStore";
import useProductOpenApi from "apiClients/useProductOpenApiClientV2";

interface PreparedTableDataToSave {
  id: BaseKey;
  payload: FlexibleIProductUpdatePayload;
}

const useSaveStackData = () => {
  const { setUpdateDataSourceIsLoading, setUpdateDataSourceError, setReportsSummary ,setIsSaveReportModalVisible: setIsModalVisible } = useDataGridStore();
  const { makeHttpRequestThrowErrors } = useHttpClient();

  const saveStackData = async (patchData: PreparedTableDataToSave[]) => {
    if (!patchData || patchData?.length === 0) return;
    // if (!Array.isArray(patchData) || patchData.length === 0) return;
    setUpdateDataSourceIsLoading(true);

    try {
      const chunkReports: SaveStackDataResult[] = [];
      const batchSize = patchData.length > 10 ? 10 : patchData.length;
      const patchDataChunks = [];

      for (let i = 0; i < patchData.length; i += batchSize) {
        const chunk = patchData.slice(i, i + batchSize);
        patchDataChunks.push(chunk);
      }

      for (const chunk of patchDataChunks) {
        const patchPromises = chunk.map(async ({ id, payload }) => {
          if (id && payload) {
            try {
              const response = await makeHttpRequestThrowErrors(
                "PATCH",
                `/products/${id}`,
                payload,
              );
              return { status: 'fulfilled', id };
            } catch (error) {
              return { status: 'rejected', id, reason: error };
            }
          }
          return { status: 'invalid', id, reason: 'Invalid ID or payload' };
        });

        const results: PromiseSettledResult<{ status: string; id: BaseKey; reason?: any }>[] = await Promise.allSettled(patchPromises);

        const chunkReport = results.reduce((acc: SaveStackDataResult, result, index) => {
          if (result.status === 'fulfilled') {
            if (result.value.status === 'fulfilled') {
              acc.success.push(result.value.id);
            } else {
              acc.failure.push({ id: result.value.id, reason: result.value.reason });
            }
          } else {
            acc.failure.push({ id: chunk[index].id, reason: result.reason });
          }
          return acc;
        }, { success: [], failure: [] });

        chunkReports.push(chunkReport);
      }

      const reportsSummary = chunkReports.reduce((acc, report) => {
        acc.success = acc.success.concat(report.success);
        acc.failure = acc.failure.concat(report.failure);
        return acc;
      }, { success: [], failure: [] });

      setReportsSummary(reportsSummary);
  
    } catch (error: any) {
      notification.error({
        message: "Unexpected Error",
        description: error?.message || 'Something went wrong.'
      });
      setUpdateDataSourceError({ isError: true, errorMessage: "", error });
    } finally {
      setUpdateDataSourceIsLoading(false);
      setIsModalVisible(true);
    }
  };

  return { saveStackData };
};

const useFetchProductsByFilterStr = () => {
  const { getProducts, getProductsByIds } = useProductOpenApi();
  const { 
    dataGridfilterStr, 
    setDataSource, 
    setDataSourceIsLoading, 
    setDataSourceError, 
    clearDataGridStoreOnFetch 
  } = useDataGridStore();
  const { activeLanguage } = useLocaleStore();

  const fetchDataGridProducts = async () => {
    clearDataGridStoreOnFetch();

    if (!dataGridfilterStr) return;

    setDataSourceIsLoading(true);
    try {
      const pageSize = 50;
      let fetchedProducts: Product[] = [] ;
      let page = 0;
      let hasMorePages = true;
  
      while (hasMorePages) {
        const response = await getProducts({
          filters: dataGridfilterStr,
          pageSize,
          page,
        });
  
        if (response?.items && response.items.length > 0) {
          fetchedProducts.push(...response.items);
          page++; 
          hasMorePages = response.hasNextPage;
        } else {
          hasMorePages = false;
        }
      }

      const relatedProductMainItemNoByIdMap = await getRelatedProductsMainItemNoMap(fetchedProducts, getProductsByIds);
      const transformedItems = transformData(fetchedProducts, activeLanguage?.id, relatedProductMainItemNoByIdMap);
      
      setDataSource(transformedItems);
    } catch (error: any) {
      setDataSourceError({
        isError: true,
        errorMessage: error?.message || 'Failed to fetch products',
        error,
      });
      notification.error({
        message: "Error",
        description: error?.statusText || 'Something went wrong fetching products.'
      });
    } finally {
      setDataSourceIsLoading(false);
    }
  };

  return { fetchDataGridProducts };
};

// Add a companion hook for handling changes
const useFetchProductsByFilterStrChange = () => {
  const { fetchDataGridProducts } = useFetchProductsByFilterStr();
  const { dataGridfilterStr } = useDataGridStore();
  const { activeLanguage } = useLocaleStore();
  const initialMount = useRef(true);

  useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false;
      return;
    }
    fetchDataGridProducts();
  }, [dataGridfilterStr, activeLanguage?.id]);
};

export { 
  useSaveStackData, 
  useFetchProductsByFilterStr,
  useFetchProductsByFilterStrChange 
};


// Helper functions
const collectUniqueProductIds = (products: Product[]): number[] => {
  const sparePartIds = Array.from(new Set(products.map((v) => v.spareParts).flat()));
  const relatedPartIds = Array.from(new Set(products.map((v) => v.relatedParts).flat()));
  const accessoryIds = Array.from(new Set(products.map((v) => v.accessories).flat()));
  return Array.from(new Set([...sparePartIds, ...relatedPartIds, ...accessoryIds].filter((id): id is number => id !== null)));
};

const getRelatedProductsMainItemNoMap = async (
  products: Product[],
  getProductsByIds: (ids: number[]) => Promise<ResponseWrapperMany<Product> | undefined>
): Promise<Record<number, string>> => {
  const relatedProductIds = collectUniqueProductIds(products);
  let relatedProductMainItemNoByIdMap: Record<number, string> = {};
  
  if (relatedProductIds?.length > 0) {
    const response = await getProductsByIds(relatedProductIds);
    if (response) {
      relatedProductMainItemNoByIdMap = (('items' in response ? response.items : response) as Product[])
        .reduce<Record<number, string>>(
          (acc, product) => ({
            ...acc,
            [product.id]: product.mainItemNo || ''
          }), 
          {}
        );
    }
  }
  return relatedProductMainItemNoByIdMap;
};

const transformData = (
  originalData: Product[],
  activeLanguageId: number = 1,
  relatedProductMainItemNoByIdMap: Record<number, string>
): any[] => {
  const transformedData = originalData?.map((entry) => {
    return {
      id: entry.id,
      createdAt: entry.createdAt,
      modifiedAt: entry.modifiedAt,
      mainItemNo: entry.mainItemNo,
      internalComments: entry.internalComments,
      externalId: entry.externalId,
      name: enhanceProduct(entry, activeLanguageId)?.getName(),
      // Create enhanceProduct to get ean
      ean:
        entry.productItems?.find(
          (v: { itemNo: any }) => v?.itemNo == entry.mainItemNo
        )?.ean ?? "",
      secondaryName: enhanceProduct(entry, activeLanguageId)?.getSecondaryName(),
      brandId: entry.brandId,
      brandName: enhanceProduct(entry, activeLanguageId)?.getBrandName(),
      groupName: entry?.productItems?.find((v: any) => v?.itemNo == entry.mainItemNo)?.group?.name ?? "",
      groupId: entry?.productItems?.find((v: any) => v?.itemNo == entry.mainItemNo)?.groupId ?? 0,
      descriptionSupplier: enhanceProduct(
        entry,
        activeLanguageId
      )?.getLongDescriptionSupplier(),
      description: enhanceProduct(
        entry,
        activeLanguageId
      )?.getDescription(),
      shippingMethod: entry.shippingMethod,
      completenessPercentage: enhanceProduct(
        entry,
        activeLanguageId
      )?.getCompleteness()?.percentage,
      completenessText: enhanceProduct(
        entry,
        activeLanguageId
      )?.getCompleteness()?.message,
      isPublished: entry.isPublished,
      sparePartIds: entry.spareParts,
      relatedPartIds: entry.relatedParts,
      accessoryIds: entry.accessories,
      productStatus: entry.productStatus,
      // change structure of salesChannels
      salesChannels: entry.salesChannels?.map((v) => ({
        id: v.salesChannel.id,
        name: v.salesChannel.name,
      })),
      images: enhanceProduct(entry, activeLanguageId)?.getImages().map(
        (image) => ({
          id: image.id,
          url: image.smallUrl,
          fileType: image.fileType,
          // relationType: RelationType.ProductImage,
          relationType: image.relationType,
          extension: image.extension,
          previewUrl: image.originalUrl,
        })
      ),
      dimensionalSheets: enhanceProduct(entry, activeLanguageId)?.getDimensionalSheets().map(
        (sheet) => ({
          id: sheet.id,
          url: sheet.smallUrl,
          fileType: sheet.fileType,
          // relationType: RelationType.DimensionalSheet,
          relationType: sheet.relationType,
          extension: sheet.extension,
          previewUrl: sheet.originalUrl,
        })
      ),
      safetySheets: enhanceProduct(entry, activeLanguageId)?.getSafetySheets().map(
        // Add mainItemNo to spareParts, relatedParts, accessories, use new endpoint
        (sheet) => ({
          id: sheet.id,
          url: sheet.smallUrl,
          fileType: sheet.fileType,
          relationType: sheet.relationType,
          extension: sheet.extension,
          previewUrl: sheet.originalUrl,
        })
      ),
      manuals: enhanceProduct(entry, activeLanguageId)?.getManuals().map(
        (manual) => ({
          id: manual.id,
          url: manual.smallUrl,
          fileType: manual.fileType,
          relationType: manual.relationType,
          extension: manual.extension,
          previewUrl: manual.originalUrl,
        })
      ),
      categories: enhanceProduct(entry, activeLanguageId).getCategories(),
      spareParts: entry?.spareParts?.map((id) => ({
        id: id,
        relationType: RelationType.SpareParts,
        mainItemNo: relatedProductMainItemNoByIdMap[id] || ''
      })) ?? [],
      relatedParts: entry.relatedParts?.map((id) => ({
        id: id,
        relationType: RelationType.RelatedParts,
        mainItemNo: relatedProductMainItemNoByIdMap[id] || ''
      })) ?? [],
      accessories: entry.accessories?.map((id) => ({
        id: id,
        relationType: RelationType.Accessories,
        mainItemNo: relatedProductMainItemNoByIdMap[id] || ''
      })) ?? [],
    };
  });
  return transformedData ?? [];
};