import { Modal } from "antd";

interface LockedMenuOverlayProps {
  isModalVisible: boolean;
  confirmUnlock: () => void;
  cancelUnlock: () => void;
  title?: string;
  message?: string;
  okText?: string;
  cancelText?: string;
}

const LockedMenuModal = ({isModalVisible, confirmUnlock, cancelUnlock, title, message, okText, cancelText}: LockedMenuOverlayProps) => {
  return (
    <Modal
      title={title ?? "Menyn är låst"}
      open={isModalVisible}
      onOk={confirmUnlock}
      onCancel={cancelUnlock}
      okText={okText ?? "Lås upp menyn"}
      cancelText={cancelText ?? "Avbryt"}
    >
      <p>{message ?? "Menyn är låst på grund av att du har osparade ändringar i din tabelldata. Byter du lista kommer dina ändringar inte sparas."}</p>
    </Modal>
  );
};

export default LockedMenuModal;
