import React, { useEffect, useState } from "react";
import { Button, Flex, Input, Modal, Table, TableColumnsType } from "antd";
import styled from "@emotion/styled";
import useHttpClient from "components/hooks/useHttpClient";
import { BaseKey } from "@refinedev/core";
import { IProductItem } from "interfaces/index.js";
import { useProductVariantsStore } from "stores/productVariantsStore";
import { set, update } from "lodash";
import { SaveOutlined, UndoOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

interface SpecStatsModalProps {
  onClose: () => void;
  isOpen: boolean;
}

interface VariantsTableData extends IProductItem {
  key: BaseKey;
}

const TableWrapper = styled.div`
  .ant-table-cell {
    padding: 20px 0px !important;
  }
  td .ant-table-cell {
    cursor: pointer;
  }

  .ant-table-footer {
    margin-left: 0px !important;
    padding-left: 0px !important;
    margin-right: 0px !important;
    padding-right: 0px !important;
  }
`;

const VariantEditModal: React.FC<SpecStatsModalProps> = ({
  onClose,
  isOpen,
}) => {
  const { makeHttpRequest } = useHttpClient();
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [variantTableData, setVariantTableData] = useState<VariantsTableData[]>([]);
  const { productVariants, updateProductVariantsProperty } = useProductVariantsStore();

  const columns: TableColumnsType<VariantsTableData> = [
    {
      title: "Namn",
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (text, record) => (
        <div style={{ paddingRight: "0.7rem", paddingLeft: "0.2rem" }}>
          <Input
            disabled={isLoading}
            value={record?.name || ""}
            onChange={(e) => {
              setVariantTableData((prev) => {
                const newData = prev ? [...prev] : [];
                const foundItem = newData.find((item) => item?.id === record?.id);
                if (foundItem && e.target.value !== undefined) {
                  foundItem.name = e.target.value;
                }
                return newData;
              });
            }}
          />
        </div>
      ),
    },
    {
      title: "Sekundär Benämning",
      dataIndex: "secondaryName",
      key: "secondaryName",
      width: 200,
      render: (text, record) => <div>{record?.secondaryName}</div>,
    },
    {
      title: "Artikelnummer",
      dataIndex: "itemNo",
      key: "itemNo",
      width: 80,
      render: (text, record) => <div>{record?.itemNo}</div>,
    },
    {
      title: "",
      dataIndex: "",
      key: "restore",
      width: 20,
      render: (text, record) => (
        <Tooltip placement="topRight" title={"Återställ"}>
          <Button
            onClick={() => {
              const itemToRestore = productVariants.find((item) => item?.id === record?.id)?.name || "";
              setVariantTableData((prev) => {
                if (!prev) return [];
                return prev?.map((dataItem) => {
                  if (dataItem?.id == record?.id) {
                    return { ...dataItem, name: itemToRestore };
                  }
                  return dataItem;
                });
              });
            }}
            type="text"
            icon={<UndoOutlined />}
            disabled={isLoading}
          ></Button>
        </Tooltip>
      ),
    },
  ];

  useEffect(() => {
    if (!isOpen) return;
    const data = productVariants?.map((productItem: IProductItem) => {
      return { key: productItem?.id, ...productItem };
    });
    setVariantTableData(data)
  }, [isOpen, productVariants]);


  const handleUpdateVariant = async (leaveOnSave: boolean) => {
    // Filter out the items that have been edited
    const modifiedTableItems = variantTableData?.filter((tableItem) => {
      const correspondingVariant = productVariants?.find(
        (variant) => variant?.id == tableItem?.id
      );
      return correspondingVariant
        ? correspondingVariant?.name !== tableItem?.name
        : false;
    });

    // If no items have been edited, close the modal or do nothing
    if (!modifiedTableItems || modifiedTableItems?.length === 0) {
      if (leaveOnSave) {
        onClose();
      }
      return;
    }

    setIsloading(true);

    try {
      const promises = modifiedTableItems?.map((item) =>
        makeHttpRequest("PATCH", `/productItems/${item?.id}`, {
          name: item?.name,
        })
      );

      const responses = await Promise.allSettled(promises);

      const successResponses: { variantId: React.Key; value: string }[] = [];

      responses?.forEach((result, index) => {
        if (result?.status === "fulfilled") {
          successResponses?.push({
            variantId: modifiedTableItems[index]?.id,
            value: modifiedTableItems[index]?.name,
          });
          // console.log(`Operation ${index + 1} succeeded:`, result.value);
        } else {
          // console.log(`Operation ${index + 1} failed:`, result.reason);
        }
      });

      updateProductVariantsProperty(successResponses, "name");
    } catch (error) {
      // console.warn("error");
    } finally {
      setIsloading(false);
      if (leaveOnSave) {
        onClose();
      }
    }
  };

  return (
    <Modal
      title="Redigera varianter"
      open={isOpen}
      onCancel={onClose}
      okButtonProps={{ hidden: false }}
      okText="Spara"
      okType="primary"
      footer={null}
    >
      <span>
        Ändra namn på variant/varianter och tryck på spara för att uppdatera.
      </span>
      <TableWrapper>
        <Table
          columns={columns}
          dataSource={variantTableData}
          pagination={false}
          loading={isLoading}
          scroll={{ y: 240 }}
          footer={() => (
            <Flex justify="flex-end" gap={"0.5rem"} style={{marginTop: "0.5rem", paddingRight: "0px"}}>
              <Button
                type="default"
                icon={<SaveOutlined />}
                loading={isLoading}
                disabled={isLoading}
                onClick={() => handleUpdateVariant(false)}
              >
                Spara
              </Button>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                loading={isLoading}
                disabled={isLoading}
                onClick={() => handleUpdateVariant(true)}
              >
                Spara och stäng
              </Button>
            </Flex>
          )}
        />
      </TableWrapper>
    </Modal>
  );
};

export default VariantEditModal;
