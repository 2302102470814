import styled from '@emotion/styled';
import { Select } from 'antd';
import { useState, useEffect, useRef } from 'react';

type ArrayElementType<T> = T extends (infer U)[] ? U : never;

interface MagicSelectProps<T, V> {
    onSearch?: (value: string) => Promise<T[]>;
    formatOption: (option: T) => { value: V; label: string };
    placeholder?: string;
    onSelect: (value?: ArrayElementType<V>) => void;
    fetchOptions?: () => Promise<T[]>;
    Options?: T[];
    value?: V;
    fetchMissingOptions?: (value: V) => Promise<T[]>;
    isTableCell?: boolean;
    widthRem?: number;
    growHorizontally?: boolean;
    disabled?: boolean;
    onClear?: () => void;
}

const tableCellStyle: React.CSSProperties = {
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  width: "100%",
  height: "100%",
  color: "#9ba7b4",
  left: 0,
  top: 0,
};

const SearchableSelectWrapper = styled.div<{
  isTableCell?: boolean;
  widthRem?: number;
  growHorizontally?: boolean;
}>`

  .ant-select-selector {
    border: ${(props) =>
      props.isTableCell ? "0px solid transparent !important" : undefined};
    border-radius: ${(props) => (props.isTableCell ? "0px " : undefined)};
  }
  .ant-select {
    ${(props) => props.widthRem && `width: ${props.widthRem}rem;`}
    ${(props) => !props.widthRem && props.growHorizontally && `width: 100%;`}
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
`;

const MagicSelect = <T, V>({ 
    onSearch, 
    formatOption, 
    placeholder, 
    onSelect, 
    fetchOptions,
    Options, 
    fetchMissingOptions, 
    value, 
    isTableCell, 
    widthRem, 
    growHorizontally,
    disabled = false,
    onClear
}: MagicSelectProps<T, V>) => {
    const [options, setOptions] = useState<T[]>([]);
    const [searchResults, setSearchResults] = useState<T[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isSearching, setIsSearching] = useState<boolean>(false);

    const hasFetchedInitialOptions = useRef(false);

    // Initialize options
    useEffect(() => {
        const fetchInitialOptions = async () => {
            if (fetchOptions && !Options && !hasFetchedInitialOptions.current) {
                hasFetchedInitialOptions.current = true;
                setLoading(true);
                try {
                    const initialOptions = await fetchOptions();
                    setOptions(initialOptions);
                } catch (error) {
                    console.error('Error fetching initial options:', error);
                }
                setLoading(false);
            }
        };
        fetchInitialOptions();
    }, [fetchOptions, Options]);

    // Handle Options prop changes
    useEffect(() => {
        if (Options) {
            setOptions(prevOptions => {
                const existingValues = new Set(prevOptions.map(opt => formatOption(opt).value));
                const newOptions = Options.filter(opt => !existingValues.has(formatOption(opt).value));
                return [...prevOptions, ...newOptions];
            });
        }
    }, [Options, formatOption]);

    // Fetch missing option for initial value
    useEffect(() => {
        const fetchMissingValue = async () => {
            if (value && !options.some(opt => formatOption(opt).value === value) && fetchMissingOptions) {
                setLoading(true);
                try {
                    const missingOptions = await fetchMissingOptions(value);
                    if (missingOptions) {
                        setOptions(prevOptions => [...prevOptions, ...missingOptions]);
                    }
                } catch (error) {
                    console.error('Error fetching missing options:', error);
                }
                setLoading(false);
            }
        };
        fetchMissingValue();
    }, [value, options, fetchMissingOptions, formatOption]);

    const handleSearch = async (searchValue: string) => {
        if (!onSearch) return;
        
        setIsSearching(true);
        setLoading(true);
        
        try {
            const results = await onSearch(searchValue);
            setSearchResults(results || []);
        } catch (error) {
            console.error('Error during search:', error);
            setSearchResults([]);
        }
        
        setLoading(false);
        setIsSearching(true);
    };

    const handleClear = () => {
        onClear?.();
        onSelect(undefined);
        setIsSearching(false);
        setSearchResults([]);
    };

    const displayedOptions = isSearching ? searchResults : options;

    return (
      <SearchableSelectWrapper
        isTableCell={isTableCell}
        widthRem={widthRem}
        growHorizontally={growHorizontally}
      >
        <Select
          disabled={disabled}
          className={`${growHorizontally ? "w-full" : ""} ${
            widthRem ? `w-${widthRem}` : ""
          }`}
          style={isTableCell ? tableCellStyle : undefined}
          value={value}
          showSearch={!!onSearch}
          allowClear
          placeholder={placeholder}
          onSearch={handleSearch}
          options={displayedOptions.map(formatOption)}
          onSelect={(value) => onSelect(value as ArrayElementType<V>)}
          loading={loading}
          onClear={handleClear}
          filterOption={false}
          notFoundContent={loading ? "Loading..." : null}
        />
      </SearchableSelectWrapper>
    );
};

export default MagicSelect;
