export const getColumnVisibilityFromStorage = (storageKey: string): Record<string, boolean> => {
  try {
    const rawData = localStorage.getItem(storageKey);
    const parsedData = rawData ? JSON.parse(rawData) : {};

    if (!parsedData || typeof parsedData !== "object") {
      throw new Error("Invalid structure in localStorage");
    }

    return parsedData;
  } catch (error) {
    console.warn(`Error accessing ${storageKey}:`, error);
    const fallback = {};
    localStorage.setItem(storageKey, JSON.stringify(fallback));
    return fallback;
  }
};

export const updateColumnVisibilityInStorage = (
  storageKey: string,
  columnName: string,
  visible: boolean
): void => {
  if (!columnName) return;

  try {
    // Retrieve existing visibility state
    const savedVisibility = localStorage.getItem(storageKey);
    const columnVisibility = savedVisibility ? JSON.parse(savedVisibility) : {};

    // Validate the parsed data
    if (typeof columnVisibility !== "object" || columnVisibility === null) {
      console.warn(`Invalid data in ${storageKey}, resetting visibility.`);
      throw new Error("Invalid localStorage structure");
    }

    // Update the column visibility
    columnVisibility[columnName] = visible;

    // Save back to localStorage
    localStorage.setItem(storageKey, JSON.stringify(columnVisibility));
  } catch (error) {
    console.warn(`Error updating column visibility in ${storageKey}:`, error);
    
    // Reset to a new object with the updated column visibility
    const columnVisibility = { [columnName]: visible };
    localStorage.setItem(storageKey, JSON.stringify(columnVisibility));
  }
};


export const isFilterFieldFocused = () => {
  const focusedElement = document.activeElement;
  const isFocused = focusedElement?.closest(
    ".InovuaReactDataGrid__column-header__filter-wrapper"
  );
  return !!isFocused;
};