/**
 * KeyEventPropagationStopper Component
 *
 * This component wraps its children and stops key events (like `onKeyDown`) from
 * propagating to parent elements in the DOM. It is particularly useful when you
 * want to isolate keyboard interactions within a specific modal or component,
 * ensuring that parent handlers (like global shortcuts) do not interfere.
 *
 * Default browser behavior is preserved because this component only stops the
 * propagation of events, not their default action.
 *
 */
const KeyEventPropagationStopper: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div onKeyDown={(event) => event.stopPropagation()}>
    {children}
  </div>
);

export default KeyEventPropagationStopper;