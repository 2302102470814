import { Category, ImageSizeEnum, LocalizedDto, MediaAsset, MediaAssetRelation, MediaAssetResolution, ProductCategory } from "./apiTypes";

interface ResponseWrapper<T extends Base> {
  value: T;
  metaData: {
    get: MetaDataItem[];
    create: MetaDataItem[];
    update: MetaDataItem[];
  };
}

export interface Base {
  id: number;
  createdAt: string;
  modifiedAt: string;
}

interface MetadataResponseWrapper {
  metaData: {
    get: MetaDataItem[];
    create: MetaDataItem[];
    update: MetaDataItem[];
  };
}

interface MetaDataItem {
  fullName: string;
  propertyPaths: string[];
  fields: Field[];
}

interface Field {
  name: string;
  fieldType: number;
  isEntity: boolean;
  isEnumeration: boolean;
  isEnum: boolean;
  enumInfo: EnumInfo[];
}

interface EnumInfo {
  initValue: number;
  enumValue: string;
}

enum SortOrderEnum {
  ASC = 0,
  DESC = 1,
}

interface GetManyRequestOptions {
  page?: number;
  pageSize?: number;
  order?: SortOrderEnum;
  orderByField?: string;
  filters?: string;
}

interface ErrorResponse {
  message: string;
}

enum FilterOperatorEnum {
  EQUALS = "equals",
  NOT_EQUALS = "notequals",
  CONTAINS = "contains",
  STARTS_WITH = "startswith",
  ENDS_WITH = "endswith",
  LESS_THAN = "lessthan",
  GREATER_THAN = "greaterthan",
  LESS_THAN_OR_EQUAL = "lessthanorequal",
  GREATER_THAN_OR_EQUAL = "greaterthanorequal",
  Empty = "empty",
	NotEmpty = "notempty",
	IN = "in"
}

export enum LogicalOperatorEnum {
  AND = "@@",
  OR = "||",
}

export interface FilterGroup {
  operator: LogicalOperatorEnum;
  value: (FilterItem | FilterGroup)[];
}

export interface FilterItem {
  fieldName: string;
  value: string;
  operator: FilterOperatorEnum;
}

declare global {
  interface Array<T> {
    findFilterIndexByFieldName(fieldName: string): number;
    updateFilterValueByFieldName(fieldName: string, value: string): void;
    addFilterItem(fieldName: string, value: string, operator: FilterOperatorEnum): FilterItem;
    filterItemExists(fieldName: string): boolean;
  }
}

export class MediaAssetInstance {
  resolutions: MediaAssetResolution[];
  id: number;
  order: number;
  originalUrl: string;
  smallUrl: string;
  mediumUrl: string;
  fileType: string;
  relationType: string;
  extension: string;
  constructor(mediaProductRelation: MediaAssetRelation) {
    this.resolutions = mediaProductRelation.mediaAsset.resolutions ?? [];
    this.id = mediaProductRelation.mediaAsset.id ?? 0;
    this.order = mediaProductRelation.order ?? 0;
    this.originalUrl = mediaProductRelation.mediaAsset.url ?? '';
    this.smallUrl = mediaProductRelation.mediaAsset.resolutions?.find((r: MediaAssetResolution) => r.size === ImageSizeEnum.Small)?.url ?? '';
    this.mediumUrl = mediaProductRelation.mediaAsset.resolutions?.find((r: MediaAssetResolution) => r.size === ImageSizeEnum.Medium)?.url ?? '';
    this.fileType = mediaProductRelation.fileType ?? '';
    this.relationType = mediaProductRelation.relationType ?? '';
    this.extension = mediaProductRelation.mediaAsset.extension ?? '';
  }
}

export class CategoryInstance {
  id: number;
  externalId: number;
  name: string;
  level: number;
  parentCategory: CategoryInstance | null;
  constructor(category: Category, languageId: number) {
    this.id = category.id ?? 0;
    this.externalId = category.externalId ?? 0;
    this.name = category.names?.find((t: LocalizedDto) => t.language.id === languageId)?.value ?? '';
    this.level = category.level ?? 0;
    this.parentCategory = category.parentCategory ? new CategoryInstance(category.parentCategory, languageId) : null;
  }
}

Array.prototype.findFilterIndexByFieldName = function (fieldName: string): number {
  return this.findIndex((filter: FilterItem) => filter.fieldName === fieldName);
};

Array.prototype.updateFilterValueByFieldName = function (fieldName: string, value: string): void {
  const index = this.findFilterIndexByFieldName(fieldName);
  if (index !== -1) {
    this[index].value = value;
  }
};

Array.prototype.addFilterItem = function (fieldName: string, value: string, operator: FilterOperatorEnum): FilterItem {
  const newFilterItem: FilterItem = {
    fieldName: fieldName,
    value: value,
    operator: operator
  };
  this.push(newFilterItem);
  return newFilterItem;
};

Array.prototype.filterItemExists = function (fieldName: string): boolean {
  return this.findFilterIndexByFieldName(fieldName) !== -1;
};

export type { ResponseWrapper, MetaDataItem, Field, EnumInfo, GetManyRequestOptions, MetadataResponseWrapper, ErrorResponse };
export { SortOrderEnum, FilterOperatorEnum };
