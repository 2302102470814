import { Button, Checkbox, Input, Select, Tag, Tabs } from "antd";
import { IProductGroup } from "interfaces";
import { EntityMetaData, EntityType, EnumInfo, FieldMetaData } from "interfaces/apiTypes.js";
import { FilterGroup, FilterItem, FilterOperatorEnum, LogicalOperatorEnum } from "interfaces/baseTypes";
import { EditIcon, X } from "lucide-react";
import React, { useEffect, useState, useTransition } from "react";
import { useTranslation } from "react-i18next";
import useMetadataStore from "stores/metadataStore";

const operatorMapping: { [key: number]: FilterOperatorEnum[] } = {
  1: [FilterOperatorEnum.Empty, FilterOperatorEnum.NotEmpty],
  2: [FilterOperatorEnum.EQUALS, FilterOperatorEnum.NOT_EQUALS, FilterOperatorEnum.CONTAINS, FilterOperatorEnum.STARTS_WITH, FilterOperatorEnum.ENDS_WITH, FilterOperatorEnum.Empty, FilterOperatorEnum.NotEmpty],
  3: [FilterOperatorEnum.EQUALS, FilterOperatorEnum.NOT_EQUALS, FilterOperatorEnum.LESS_THAN, FilterOperatorEnum.GREATER_THAN, FilterOperatorEnum.LESS_THAN_OR_EQUAL, FilterOperatorEnum.GREATER_THAN_OR_EQUAL, FilterOperatorEnum.Empty, FilterOperatorEnum.NotEmpty],
  4: [FilterOperatorEnum.EQUALS, FilterOperatorEnum.NOT_EQUALS, FilterOperatorEnum.LESS_THAN, FilterOperatorEnum.GREATER_THAN, FilterOperatorEnum.LESS_THAN_OR_EQUAL, FilterOperatorEnum.GREATER_THAN_OR_EQUAL, FilterOperatorEnum.Empty, FilterOperatorEnum.NotEmpty],
  5: [],
  6: [FilterOperatorEnum.EQUALS, FilterOperatorEnum.NOT_EQUALS],
  7: [FilterOperatorEnum.EQUALS, FilterOperatorEnum.NOT_EQUALS, FilterOperatorEnum.LESS_THAN, FilterOperatorEnum.GREATER_THAN, FilterOperatorEnum.LESS_THAN_OR_EQUAL, FilterOperatorEnum.GREATER_THAN_OR_EQUAL],
  8: [FilterOperatorEnum.EQUALS, FilterOperatorEnum.NOT_EQUALS, FilterOperatorEnum.LESS_THAN, FilterOperatorEnum.GREATER_THAN, FilterOperatorEnum.LESS_THAN_OR_EQUAL, FilterOperatorEnum.GREATER_THAN_OR_EQUAL],
  9: [FilterOperatorEnum.EQUALS, FilterOperatorEnum.NOT_EQUALS, FilterOperatorEnum.CONTAINS, FilterOperatorEnum.STARTS_WITH, FilterOperatorEnum.ENDS_WITH, FilterOperatorEnum.Empty, FilterOperatorEnum.NotEmpty],
  10: [FilterOperatorEnum.EQUALS, FilterOperatorEnum.NOT_EQUALS, FilterOperatorEnum.LESS_THAN, FilterOperatorEnum.GREATER_THAN, FilterOperatorEnum.LESS_THAN_OR_EQUAL, FilterOperatorEnum.GREATER_THAN_OR_EQUAL, FilterOperatorEnum.Empty, FilterOperatorEnum.NotEmpty],
};

export const FilterBuilder = ({
  metadata,
  onFiltersChange,
  initialFilterGroup,
  whiteListedFieldKeys,
  operator
}: {
  metadata: EntityMetaData | undefined;
  onFiltersChange: (filterGroup: FilterGroup) => void;
  initialFilterGroup?: FilterGroup;
  operator?: LogicalOperatorEnum;
  whiteListedFieldKeys?: string[];
}) => {
  const [filterGroup, setFilterGroup] = useState<FilterGroup>({
    operator: operator ?? LogicalOperatorEnum.AND,
    value: []
  });
  const [selectedField, setSelectedField] = useState<FieldMetaData | undefined>();
  const [selectedOperator, setSelectedOperator] = useState<FilterOperatorEnum>();
  const [value, setValue] = useState<string | undefined>(undefined);
  const [fieldKeys, setFieldKeys] = useState<string[]>([]);
  const { t } = useTranslation();
  const [fieldsDict, setFieldsDict] = useState<{ [key: string]: any }>({});
  const [isOperatorVisible, setIsOperatorVisible] = useState(true);
  const [isValueVisible, setIsValueVisible] = useState(true);
  const [editingIndex, setEditingIndex] = useState<number | undefined>();

  const { getBrandById, getBrands, getProductGroups, getProductGroupById } = useMetadataStore();

  useEffect(() => {
    if(initialFilterGroup){
      setFilterGroup(initialFilterGroup);
    }
  }, [initialFilterGroup]);

  const setAllFieldKeys = (metadata: EntityMetaData | undefined) => {
    if (!metadata || fieldKeys.length > 0) {
      return;
    }

    let fieldKeysTemp: string[] = [];
    const distinctLanguageKeys: string[] = [];
    const mainEntity = metadata.metaData?.get?.find(
      (entity) =>
        entity.propertyPaths?.includes("")
    );

    const findFields = (entities: EntityType[], parentField: string = "") => {
      entities.forEach((entity) => {
        entity.fields?.forEach((field) => {
          if (!field.isFilterable || fieldKeys.includes(field?.name ?? "")) {
            return;
          }
          
          // Check if the field is the main entity
          // const isMainEntity = mainEntity && entity === mainEntity;

          const fullFieldName = parentField
              ? `${parentField}.${field.name}`
              : field.name;

          // Add the field to the fieldsDict
          setFieldsDict(prev => ({ ...prev, [fullFieldName ?? ""]: field })); // Store field in fieldsDict

          if (field.isEntity) {
            const nextEntity = metadata.metaData?.get?.find((e) =>
              e.propertyPaths?.includes(fullFieldName ?? "")
            );

            if(field.isEnumeration){
              fieldKeysTemp.push(fullFieldName ?? "");
            }

            if (nextEntity) {
              findFields([nextEntity], fullFieldName ?? ""); // Pass the full field name as the parent
            }
          } else {
            fieldKeysTemp.push(fullFieldName ?? "");
            if(!distinctLanguageKeys.includes(field.languageKey ?? "")){
              distinctLanguageKeys.push(field.languageKey ?? "");
            }
          }
        });
      });
    };

    if (mainEntity) {
      findFields([mainEntity]);
    }
    if(whiteListedFieldKeys && whiteListedFieldKeys.length > 0){
      fieldKeysTemp = fieldKeysTemp.filter(field => whiteListedFieldKeys.includes(field));
    }
    fieldKeysTemp.sort();
    setFieldKeys(fieldKeysTemp);
  };
  
  useEffect(() => {
    setAllFieldKeys(metadata);
  }, [metadata]);

  useEffect(() => {
    setFilterGroup(prev => ({ ...prev, operator: operator ?? LogicalOperatorEnum.AND }));
  }, [operator]);

  const handleAddOrUpdateFilter = (index?: number) => {
    // if (selectedField && selectedOperator && (value || !isValueVisible)) {
      const newFilterItem = {
        fieldName: selectedField?.name ?? "",
        operator: selectedOperator ?? FilterOperatorEnum.EQUALS,
        value: value ?? "",
      };

      let updatedFilterItems;
      if (index !== undefined) {
        // Update existing filter item
        updatedFilterItems = filterGroup.value.map((item, i) => (i === index ? newFilterItem : item));
      } else {
        // Add new filter item
        updatedFilterItems = [...filterGroup.value, newFilterItem];
      }

      const updatedFilterGroup = { ...filterGroup, value: updatedFilterItems };

      setFilterGroup(updatedFilterGroup);
      onFiltersChange(updatedFilterGroup);

      // Reset input fields after adding/updating
      setSelectedField(undefined);
      setSelectedOperator(undefined);
      setEditingIndex(undefined);
      setValue("");
      setIsOperatorVisible(true);
    // }
  };

  const handleRemoveFilter = (index: number) => {
    const updatedFilterItems = filterGroup.value.filter((_, i) => i !== index);
    const updatedFilterGroup = { ...filterGroup, value: updatedFilterItems };
    setFilterGroup(updatedFilterGroup);
    // Call the callback with the updated filters
    onFiltersChange(updatedFilterGroup);
  };

  const handleFieldChange = (fieldKey?: string) => {
    if(!fieldKey){
      setSelectedField(undefined);
      return;
    }
    const selectedField = { ...fieldsDict[fieldKey], name: fieldKey };
    const filteredOperators = operatorMapping[selectedField?.type] || [];
    if(filteredOperators.length == 0 && selectedField.fieldType == 5){
        setIsOperatorVisible(false);
        setSelectedOperator(FilterOperatorEnum.EQUALS);
        setValue("false");
    }else{
        setIsOperatorVisible(true);
        setValue(undefined);
        setSelectedOperator(filteredOperators[0]);
    }
    setSelectedField(selectedField);
  };

  const handleEditFilter = (index: number) => {
    const itemToEdit = filterGroup.value[index] as FilterItem;
    setSelectedField(fieldsDict[itemToEdit.fieldName]);
    setSelectedOperator(itemToEdit.operator);
    setValue(itemToEdit.value);
    setIsOperatorVisible(true);
    setIsValueVisible(true);
    // Store the index of the item being edited
    setEditingIndex(index);
  };

  const OnOperatorChange = (enumValue: FilterOperatorEnum) => {
    setSelectedOperator(enumValue);
    setIsValueVisible(enumValue !== FilterOperatorEnum.Empty && enumValue !== FilterOperatorEnum.NotEmpty);
  }

  const handleTabChange = (key: string) => {
    setFilterGroup(prev => ({ ...prev, operator: key === "0" ? LogicalOperatorEnum.AND : LogicalOperatorEnum.OR }));
  };

  const RenderValue = (fieldName: string, value: string) => {
    const field = fieldsDict[fieldName];
    switch (field?.fieldType) {
      case 3:
        return (
          <span>
            {fieldName === "BrandId"
              ? getBrandById(parseInt(value ?? "0"))?.name
              : fieldName === "ProductItems.GroupId"
              ? getProductGroupById(parseInt(value ?? "0"))?.name + " (" + getProductGroupById(parseInt(value ?? "0"))?.number + ")"
              : value}
          </span>
        );
      case 5:
        return <Checkbox checked={value.toLowerCase() === "true"} />;
      case 6:
        return (
          <span>
            {t(
              field.enumInfo?.find(
                (e: EnumInfo) => e.intValue === parseInt(value)
              )?.enumValue ?? value
            )}
          </span>
        );
      case 7:
        return <span>{new Date(value).toLocaleString("sv-SE")}</span>;
    }
    return <div>{value}</div>;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        marginTop: "20px",
      }}
    >
      <Tabs activeKey={filterGroup.operator === LogicalOperatorEnum.AND ? "0" : "1"} onChange={handleTabChange}>
        <Tabs.TabPane tab="Och" key="0" />
        <Tabs.TabPane tab="Eller" key="1" />
      </Tabs>
      {/* <pre>{JSON.stringify(selectedField, null, 2)}</pre>
      <pre>{JSON.stringify(filterGroup, null, 2)}</pre> */}
      <Select
        value={selectedField?.name ?? ""}
        allowClear
        placeholder="Välj fält"
        onChange={handleFieldChange}
        showSearch
        filterOption={(input, option) => {
          const fieldKey = option?.value as string;
          const field = fieldsDict[fieldKey];
          const searchText = [
            t(field?.languageKey ?? fieldKey),
            fieldKey
          ].join(' ').toLowerCase();
          return searchText.includes(input.toLowerCase());
        }}
      >
        <option value="">Välj fält</option>
        {fieldKeys
          .map((fieldKey) => ({ fieldKey, translated: t(fieldsDict[fieldKey]?.languageKey ?? fieldKey) }))
          .sort((a, b) => a.translated.localeCompare(b.translated))
          .map(({ fieldKey, translated }) => (
            <option key={fieldKey} value={fieldKey}>
              {translated}
            </option>
          ))}
      </Select>
      {isOperatorVisible && (
        <Select
          value={selectedOperator}
          allowClear
          showSearch
          placeholder="Välj operator"
          onChange={OnOperatorChange}
          filterOption={(input, option) => {
            const operator = option?.value as string;
            return t(operator).toLowerCase().includes(input.toLowerCase());
          }}
        >
          {Object.values(FilterOperatorEnum)
            .filter(operator => operatorMapping[selectedField?.fieldType ?? 2]?.includes(operator)) // Filter operators based on field type
            .map((operator) => (
              <option key={operator} value={operator}>
                {t(operator)}
              </option>
            ))}
        </Select>
      )}

      {isValueVisible && (() => {
        switch (selectedField?.fieldType) {
          case 3:
            return <CustomInput selectedField={selectedField} value={value} setValue={setValue} getBrands={getBrands} getProductGroups={getProductGroups} t={t} />;
          case 5:
            return (
              <div>
                <Checkbox onChange={(e) => setValue(e.target.checked.toString())}/>
              </div>
            );
          case 6:
              return (
                <Select
                  value={value}
                  onChange={(val) => setValue(val)}
                  placeholder={`Välj ${t(selectedField.languageKey ?? "")}`}
                >
                  {
                    selectedField.enumInfo?.map((enumValue) => (
                      <option key={enumValue.intValue} value={enumValue.intValue}>{t(enumValue.enumValue ?? enumValue?.intValue?.toString() ?? "")}</option>
                    ))
                  }
                </Select>
              );
          case 7:
            return (
              <Input
                type="datetime-local"
                value={value ? new Date(value).toISOString().slice(0, 16) : ''} // Convert ISO date string to datetime-local format
                onChange={(e) => {
                  const date = new Date(e.target.value);
                  setValue(date.toISOString()); // Store as ISO date string
                }}
                placeholder="Select date and time"
              />
            );
          default:
            return (
            <Input
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Enter value"
              />
            );
        }
      })()}

      <Button type="primary" onClick={() => handleAddOrUpdateFilter(editingIndex)} disabled={!selectedField || !selectedOperator || (!value && isValueVisible)}>
        {editingIndex !== undefined ? "Spara" : "Lägg till"} {/* Change button text based on edit mode */}
      </Button>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        {filterGroup.value.map((item, index) => {
          item = item as FilterItem;
          return (
          <div key={index} style={{ display: 'flex', alignItems: 'center', padding: '5px', border: '1px solid #e0e0e0', borderRadius: '4px' }}>
            <span style={{ marginRight: '10px' }}>{t(fieldsDict[item.fieldName]?.languageKey ?? item.fieldName)}</span>
            <Tag>{t(item.operator)}</Tag>
            <span style={{fontWeight: 'bold' }}>{RenderValue(item.fieldName, item.value)}</span>
            <Button onClick={() => handleRemoveFilter(index)} style={{ marginLeft: 'auto' }}>
              <X size={16} />
            </Button>
            <Button onClick={() => handleEditFilter(index)} style={{ marginLeft: '10px' }}>
              <EditIcon size={16} />
            </Button>
          </div>
          );
        })}
      </div>
    </div>
  );
};

const CustomInput = ({
  selectedField,
  value,
  setValue,
  getBrands,
  getProductGroups,
  t,
}: {
  selectedField: FieldMetaData | undefined;
  value: string | undefined;
  setValue: (val: string) => void;
  getBrands: () => { id: number; name: string }[];
  getProductGroups: () => IProductGroup[];
  t: (key: string) => string;
}) => {
  if (!selectedField) return null;

  switch (selectedField.name) {
    case "BrandId":
      return (
        <Select
          value={value}
          onChange={(val) => setValue(val)}
          placeholder="Välj varumärke"
          showSearch
          filterOption={(input, option) => {
            const label = option?.key?.toString() || '';
            return label.toLowerCase().includes(input.toLowerCase());
          }}
        >
          {getBrands()
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((brand) => (
              <option key={brand.name} value={brand.id.toString()}>
                {brand.name}
              </option>
            ))}
        </Select>
      );

    case "ProductItems.GroupId":
      return (
        <Select
          value={value}
          onChange={(val) => setValue(val)}
          placeholder="Välj varugrupp"
          filterOption={(input, option) => {
            const label = option?.key?.toString() || '';
            return label.toLowerCase().includes(input.toLowerCase());
          }}
        >
          {getProductGroups().sort((a, b) => a.name.localeCompare(b.name)).map((productGroup) => (
            <option key={productGroup.id} value={productGroup.id.toString()}>{productGroup.name} ({productGroup.number})</option>
          ))}
        </Select>
      );
    default:
      return (
        <Input
          type="number"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Enter value"
        />
      );
  }
};
