/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  Card,
} from "antd";
import React, { useEffect, useState } from "react";
import WorkTaskDrawer from "components/workTask/drawers/workTaskDrawer";
import useWorkTaskStore from "stores/workTaskStore";
import WorkTaskItems from "components/workTask/sections/workTaskItems";
import WorkTaskLists from "components/workTask/sections/workTaskLists";
import WorkTaskFilterBuilder from 'components/workTask/sections/workTaskFilterBuilder';
import styled from '@emotion/styled'
import AddWorkTaskList from 'components/workTask/drawers/addWorkTaskList';
import { useQueryClient } from '@tanstack/react-query';
import UpdateWorkTaskList from 'components/workTask/drawers/updateWorkTaskList';
import { WORK_TASKS_QUERY_KEY } from 'utility/constants';

// const HEIGHT_CORRECTION_AVOID_SCROLLBAR = 136;

const gridStyle = css`
  display: grid;
  grid-template-columns: 1fr 1fr; // Change to two 50% columns
  gap: 1rem;
`;

// const rightColumnStyle = css`
//   display: grid;
//   grid-template-rows: auto 1fr; // Change to auto for the first row
//   gap: 1rem;
//   overflow: hidden;
// `;

const StyledCard = styled(Card)`
  padding: 0 !important;
  height: 100%;
  overflow-y: auto;
  .ant-card-body {
    padding: 0 24px 24px 24px !important;
  }
`;

const StyledCardFullWidth = styled(StyledCard)`
  grid-column: span 2; // Make WorkTaskLists take full width
`;

export const WorkTaskPage: React.FC = () => {
  const { resetState, updateTaskIsOpen, setUpdateTaskIsOpen, createTaskIsOpen, setCreateTaskIsOpen, selectedWorkTask } = useWorkTaskStore();
  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      resetState()
    };
  }, []);

  const handleSubmitSuccess = () => {
    queryClient.invalidateQueries({ queryKey: [WORK_TASKS_QUERY_KEY] });
    setUpdateTaskIsOpen(false);
    setCreateTaskIsOpen(false);
  };

  return (
    <>
      <div css={gridStyle}>
        <StyledCardFullWidth>
          <WorkTaskLists sectionHeaderTitle={`Arbetslistor`} />
        </StyledCardFullWidth>

        <StyledCard>
          <WorkTaskFilterBuilder sectionHeaderTitle={`Filter/Urval`} />
        </StyledCard>
        <StyledCard>
          <WorkTaskItems sectionHeaderTitle={`Förhandsgranska`} />
        </StyledCard>
      </div>
      <WorkTaskDrawer 
        isOpen={createTaskIsOpen} 
        title="Skapa arbetslista" 
        onClose={() => setCreateTaskIsOpen(false)}
        renderContent={() => <AddWorkTaskList onSubmitSuccess={handleSubmitSuccess} />} 
      />
      <WorkTaskDrawer 
        isOpen={updateTaskIsOpen} 
        title={`Uppdatera arbetslista (${selectedWorkTask?.name ?? ""})`} 
        onClose={() => setUpdateTaskIsOpen(false)}
        renderContent={() => <UpdateWorkTaskList onUpdateSuccess={handleSubmitSuccess} taskId={selectedWorkTask?.id ?? 0} />} 
      />
    </>
  );
};
