import { BaseKey } from "@refinedev/core";
import SearchableSelect from "components/common/select/SearchableSelect";
import { IProductGroup } from "interfaces";
import { useEffect, useState } from "react";
import useDataGridStore from "stores/dataGridStore";
import useMetadataStore from "stores/metadataStore";

interface SelectGroupProps {
  isDisabled?: boolean | undefined;
  isOpen?: boolean | undefined;
}
const SelectGroup = ({isDisabled, isOpen}: SelectGroupProps) => {
  const { isloading: metaDataIsLoading, metadata } = useMetadataStore();
  const { productGroups = [] } = metadata ?? {};
  const { activeFilterSelector, setActiveFilterSelector, setDataGridFilterStr } = useDataGridStore();
  
  const [resetKey, setResetKey] = useState(0);

  useEffect(() => {
    if (
      activeFilterSelector !== "groupSelector" &&
      activeFilterSelector !== undefined
    ) {
      setResetKey((prev) => prev + 1);
    }
  }, [activeFilterSelector]);

  const handleGroupIdChange = (groupIdValue: BaseKey) => {
    setActiveFilterSelector("groupSelector");
    setDataGridFilterStr(`ProductItems.GroupId::equals::${groupIdValue}`);
  };
  
  const groupOptions = productGroups?.map((brand: IProductGroup) => ({
    value: brand?.id,
    label: `${brand?.name} (${brand?.number})`,
  }));

  return (
    <SearchableSelect
      key={resetKey}
      defaultOpen={false}
      options={groupOptions}
      onChange={handleGroupIdChange}
      placeholder={"Hämta varugrupp"}
      isLoading={metaDataIsLoading}
      isTableCell={false}
      widthRem={18}
      isDisabled={isDisabled}
      isOpen={isOpen}
      autoFocus={false}
    ></SearchableSelect>
  );
};

export default SelectGroup;
