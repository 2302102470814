import { useApiUrl, useCustom } from '@refinedev/core';
import { useQuery } from '@tanstack/react-query';
import useProductOpenApi from 'apiClients/useProductOpenApiClientV2';
import { IMetadata } from 'interfaces';
import { useEffect } from 'react';
import { useLocaleStore } from 'stores/localeStore';
import useMetadataStore from 'stores/metadataStore';

const useMetadata = () => {
  const { setMetadata, setProductMetadata, setIsLoading } = useMetadataStore();
  const { getMetadata } = useProductOpenApi();
  const { locale } = useLocaleStore();
  const apiUrl = useApiUrl();
    
  const { data, isLoading } = useCustom<IMetadata>({
    url: `${apiUrl}/metadata`,
    method: "get",
    config: {
        headers: { "lang": locale }
    },
  });

  const { data: productMetadata } = useQuery({
    queryKey: ['product-metadata'],
    queryFn: getMetadata,
    cacheTime: 0,
    staleTime: 0
  });

  useEffect(() => {
    setIsLoading(true);
  }, [locale]);

  useEffect(() => {
    setProductMetadata(productMetadata);
  }, [productMetadata]);

  useEffect(() => {
    if (data?.data) {
      setMetadata(data.data);
    }
  }, [data]);
};

export default useMetadata;