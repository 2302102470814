const TopLabel = ({
  children,
  text = "",
  type = "info", // Default type
}: {
  children: React.ReactNode;
  text?: string;
  type?: "info" | "warning" | "error" | "success";
}) => {
  const colorMap: {
    [key in "info" | "warning" | "error" | "success"]: string;
  } = {
    info: "black",
    warning: "#FFA500",
    error: "#FF0000",
    success: "#008000",
  };

  return (
    <div
      style={{ position: "relative", padding: "0", color: colorMap[type] }}
    >
      {text && (
        <span
          style={{
            position: "absolute",
            top: "-10px",
            left: 0,
            fontSize: "11px",
            zIndex: 1,
            backgroundColor: "white",
            padding: "0 5px",
          }}
        >
          {text}
        </span>
      )}
      <div style={{ position: "relative" }}>{children}</div>
    </div>
  );
};

export default TopLabel;