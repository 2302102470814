import { Button, Flex, Modal } from "antd";
import styled from "@emotion/styled";
import useDataGridStore from "stores/dataGridStore";
import { SaveOutlined } from "@ant-design/icons";
import { useFetchProductsByFilterStr } from "components/hooks/dataGrid/useDataGridSource";
import { DividerLine } from "components/separators/dividerLine";

const SaveReportModalContent = styled("div")`
  .scrollable-container {
    max-height: 500px;
    overflow-y: auto;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
  }

  .truncate {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  h4 {
    font-weight: bold;
  }
`;

const SaveReportModal = () => {
  const { isSaveReportModalVisible, setIsSaveReportModalVisible, reportsSummary } =
    useDataGridStore();

  const { fetchDataGridProducts } = useFetchProductsByFilterStr();

  const handleCloseModal = async() => {
    setIsSaveReportModalVisible(false);
    await fetchDataGridProducts();
  };

  return (
    <Modal
      title="Uppdateringsrapport"
      open={isSaveReportModalVisible}
      footer={null}
      closable={false}
      onCancel={handleCloseModal}
    >
      <SaveReportModalContent>
        {reportsSummary.failure.length > 0 ? (
          <>
            <h4>Alla produkter uppdaterades inte</h4>
            <DividerLine />
            <p>Antal uppdaterade produkter: {reportsSummary?.success.length}</p>
            <DividerLine />
            <p>Produkter som inte uppdaterades:</p>
            <ul className="scrollable-container">
              {reportsSummary.failure.map(({ id, reason }) => (
                <li key={id}>
                  <span className="truncate">
                    Id: {id}, Fel: {reason.message || reason}
                  </span>
                </li>
              ))}
            </ul>
          </>
        ) : (
          <>
            <p>Antal uppdaterade produkter: {reportsSummary?.success.length}</p>
          </>
        )}
        <Flex
          justify="flex-end"
          gap={"0.5rem"}
          style={{ marginTop: "0.5rem", paddingRight: "0px" }}
        >
          <Button type="primary" onClick={handleCloseModal}>
            OK
          </Button>
        </Flex>
      </SaveReportModalContent>
    </Modal>
  );
};

export default SaveReportModal;
