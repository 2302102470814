import useDataGridStore from "stores/dataGridStore";
import { useEffect, useState } from "react";
import SelectGroup from "./selectGroup";
import LockedMenuModal from "../lockedFilterComps/lockedMenuModal";
import LockedMenuOverlay from "../lockedFilterComps/lockedMenuOverlay";

const SelectGroupFilter = () => {
  const { updateStack, dataSourceIsLoading } = useDataGridStore();
  const [lockedMenu, setLockedMenu] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    setLockedMenu((prevLockedMenu) => {
      const currentUpdateStackLength = updateStack ? updateStack?.length : 0;
      if (prevLockedMenu && currentUpdateStackLength === 0) {
        return false; // Transition from non-zero to zero
      }
      if (!prevLockedMenu && currentUpdateStackLength > 0) {
        return true; // Transition from zero to non-zero
      }
      // Avoid state update triggering rerenders, return previous state
      return prevLockedMenu;
    });
  }, [updateStack]);

  const handleUnlockClick = () => {
    setIsModalVisible(true);
  };

  const confirmUnlock = () => {
    setLockedMenu(false);
    setIsModalVisible(false);
  };

  const cancelUnlock = () => {
    setIsModalVisible(false);
  };

  return (
    <div style={{ position: "relative" }}>
      <LockedMenuOverlay
        handleUnlockClick={handleUnlockClick}
        lockedMenu={lockedMenu}
      />
      <SelectGroup isDisabled={lockedMenu || dataSourceIsLoading}/>
      <LockedMenuModal
        isModalVisible={isModalVisible}
        confirmUnlock={confirmUnlock}
        cancelUnlock={cancelUnlock}
      />
    </div>
  );
};

export default SelectGroupFilter;
