import { Button } from "antd";
import React, { useEffect, useState } from "react";
import useWorkTaskOpenApi from "apiClients/useWorkTaskOpenApiClient";
import useWorkTaskStore from "stores/workTaskStore";
import { FilterBuilder } from "components/filters/filterBuilder";
import WorkTaskSectionHeader from "./header/sectionHeader";
import { Filter } from "lucide-react";
import { defaultSectionHeaderIconStyle } from "../styles/workTaskStyles";
import { parseFiltersToString, parseStringToFilters } from "utility/helper";
import { notification } from 'antd';
import { useQueryClient } from "@tanstack/react-query";
import { WORK_TASKS_PRODUCTS_QUERY_KEY, WORK_TASKS_QUERY_KEY } from "utility/constants";
import { FilterGroup, LogicalOperatorEnum } from "interfaces/baseTypes";
import useMetadataStore from "stores/metadataStore";

const WorkTaskFilterBuilder: React.FC<{ sectionHeaderTitle?: string }> = ({
  sectionHeaderTitle,
}) => {
  const { patchWorkTask } = useWorkTaskOpenApi();
  const { selectedWorkTask, currentFilterGroup, setCurrentFilterGroup } = useWorkTaskStore();
  const queryClient = useQueryClient();
  const [isModified, setIsModified] = useState(false);
  const { productMetadata } = useMetadataStore();

  useEffect(() => {
    setCurrentFilterGroup(parseStringToFilters(selectedWorkTask?.queryString ?? "", selectedWorkTask?.queryType === 0 ? LogicalOperatorEnum.AND : LogicalOperatorEnum.OR));
    setIsModified(false);
  }, [selectedWorkTask?.id]);

  useEffect(() => {
    setIsModified(currentFilterGroup && 
      JSON.stringify(currentFilterGroup) !== JSON.stringify(parseStringToFilters(selectedWorkTask?.queryString ?? "")));
  }, [currentFilterGroup]);

  const saveNewFilter = async() => {
    if (!selectedWorkTask?.id) {
      return;
    }

    const operator = (currentFilterGroup as FilterGroup).operator;

    const status = await patchWorkTask(selectedWorkTask.id, {
      values: [
        {
          propertyPath: "queryString",
          value: parseFiltersToString(currentFilterGroup),
        },
        {
          propertyPath: "queryType",
          value: operator === LogicalOperatorEnum.AND ? 0 : 1,
        },
      ],
    });

    if (status) {
      notification.success({
        message: 'Filter sparad',
        description: 'Sökningen har sparats.',
        placement: 'topRight',
      });
    }else{
      notification.error({
        message: 'Fel vid sparande',
        description: 'Det gick inte att spara sökningen.',
        placement: 'topRight',
      });
    }

    await queryClient.invalidateQueries({ queryKey: [WORK_TASKS_QUERY_KEY] });
    await queryClient.invalidateQueries({ queryKey: [WORK_TASKS_PRODUCTS_QUERY_KEY] });
    await queryClient.invalidateQueries({ queryKey: ['product-metadata'] });
  }

  const onQueryBuilderFiltersChange = (newFilterGroup: FilterGroup) => {
    setCurrentFilterGroup(newFilterGroup);
  }

  return (
    <div>
      <div style={{ position: 'sticky', top: 0, zIndex: 11 }}>
        <WorkTaskSectionHeader
          title={sectionHeaderTitle ?? ""}
          subText={selectedWorkTask?.id ? `${selectedWorkTask?.name ?? ""} (${selectedWorkTask?.id})` : "Ingen lista vald"}
          icon={<Filter style={defaultSectionHeaderIconStyle} />}
          showSeparatorLine={true}
          renderActionElement={() => (
            <Button
              type="primary"
              disabled={!selectedWorkTask?.id || !currentFilterGroup || !isModified}
              onClick={async () => {
                await saveNewFilter();
              }}
            >
              Spara
            </Button>
          )}
        />
      </div>
      {selectedWorkTask && selectedWorkTask?.id ? (
        <FilterBuilder
          key={selectedWorkTask.id}
          metadata={productMetadata}
          initialFilterGroup={currentFilterGroup as FilterGroup}
          onFiltersChange={(value) => onQueryBuilderFiltersChange(value)}
          whiteListedFieldKeys={["BrandId", "CreatedAt", "ShippingMethod", "ProductItems.GroupId", "IsPublished", "SalesChannels"]}
          operator={selectedWorkTask.queryType === 0 ? LogicalOperatorEnum.AND : LogicalOperatorEnum.OR}
        ></FilterBuilder>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
            height: "100%",
          }}
        >
          Ingen lista vald
        </div>
      )}
    </div>
  );
};

export default WorkTaskFilterBuilder;

