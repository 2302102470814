import React, { useEffect, useState } from "react";
import { Flex, Radio } from "antd";
import type { RadioChangeEvent } from "antd";
import { FilterItem, FilterOperatorEnum } from "interfaces/baseTypes";
import {
  addOrUpdateFilterItem,
  getEnumValuesFromMetadata,
  translateEnumValues,
} from "utility/helper";
import { useTranslation } from "react-i18next";
import { EntityMetaData, ProductStatus } from "interfaces/apiTypes";

interface ProductStatusFilterTabsProps {
  filterItems: FilterItem[];
  onTabChange: (value: number) => void;
  metadata: EntityMetaData | undefined;
}

const ProductStatusFilterTabs: React.FC<ProductStatusFilterTabsProps> = ({
  filterItems,
  onTabChange,
  metadata,
}) => {
  const { t } = useTranslation();
  const [internalStatusValue, setInternalStatusValue] = useState<number>(0);
  const [statusOptions, setStatusOptions] = useState<
    { id: number; label: string }[]
  >([]);
  
  useEffect(() => {
    if (!metadata) {
      return;
    }
    const enhancedStatusOptions = translateEnumValues(
      getEnumValuesFromMetadata(
        metadata,
        "Toolbox.Response.ProductDto.ProductDto",
        "ProductStatus"
      ),
      t
    );
    setStatusOptions(
      enhancedStatusOptions?.map((option) => ({
        id: option?.intValue ?? 0,
        label: option?.enumDisplayValue ?? "",
      }))
    );
  }, [metadata, t]);

  useEffect(() => {
    console.log("filterItems:", filterItems);
    const statusFilter = filterItems.find(item => item.fieldName === "productStatus");
    if (statusFilter) {
      setInternalStatusValue(parseInt(statusFilter.value));
    } else {
      // If no status filter exists, set a default one
      updateStatusFilter(0);
    }
  }, [filterItems]);

  const updateStatusFilter = (selectedValue: number) => {
    // addOrUpdateFilterItem(
    //   filterItems,
    //   setFilterItems,
    //   "productStatus",
    //   selectedValue.toString(),
    //   FilterOperatorEnum.EQUALS
    // );
    onTabChange(selectedValue);
    setInternalStatusValue(selectedValue);
  };

  const handleChange = (e: RadioChangeEvent) => {
    const selectedValue = parseInt(e.target.value);
    updateStatusFilter(selectedValue);
  };

  return (
    <Flex vertical gap="middle">
      <Radio.Group
        buttonStyle="solid"
        onChange={handleChange}
        value={internalStatusValue}
      >
        {statusOptions.map((tabFilter) => (
          <Radio.Button key={tabFilter.id} value={tabFilter.id}>
            {tabFilter.label}
          </Radio.Button>
        ))}
      </Radio.Group>
    </Flex>
  );
};

export default ProductStatusFilterTabs;
