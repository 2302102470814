import SelectGroupFilter from "./filters/selectFilters/group/selectGroupFilter";
import { Flex } from "antd";
import CheckBoxRowToggle from "./checkboxRowToggle";
import RowSelectionCounter from "./rowSelectionCounter";
import { SelectedRowsActions } from "./filters/actions/selectedRowsActions";
import SelectWorkTaskFilter from "./filters/selectFilters/workTask/selectWorkTaskFilter";

const FilterSectionItem = ({
  children,
  title = "",
}: {
  children: React.ReactNode;
  title?: string;
}) => {
  return (
    <Flex vertical>
      <span style={{ display: "block", marginBottom: 4, fontSize: "11px" }}>
        {title}
      </span>
      <Flex vertical justify="center" style={{ flexGrow: "1" }}>
        {children}
      </Flex>
    </Flex>
  );
};

const FilterSeprator = () => {
  return (
    <div style={{ height: "100%", width: "1px", background: "#e4e3e2" }}></div>
  );
};

const FilterSection = () => {
  return (
    <Flex gap={15} justify="center" style={{ position: "relative" }}>
      <FilterSectionItem title="Välj varugruppslista">
        <SelectGroupFilter />
      </FilterSectionItem>
      <FilterSectionItem title="Välj arbetslista">
        <SelectWorkTaskFilter />
      </FilterSectionItem>
      <FilterSeprator />
      <FilterSectionItem title="Radmark.">
        <CheckBoxRowToggle />
      </FilterSectionItem>
      <FilterSectionItem title="Antal">
        <RowSelectionCounter />
      </FilterSectionItem>
      <FilterSectionItem title="Åtgärd">
        <SelectedRowsActions />
      </FilterSectionItem>
      <FilterSeprator />
    </Flex>
  );
};

export default FilterSection;