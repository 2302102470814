import { TypeRowSelection } from "@inovua/reactdatagrid-community/types";
import { BaseKey } from "@refinedev/core";
import { create } from "zustand";

export interface IStackDataItem {
  cell: string;
  revisionId?: number;
  value?: any;
}

export interface SaveStackDataResult {
  success: BaseKey[];
  failure: { id: BaseKey; reason: any }[];
}

type DataGridStore = {
  // DataSource fetch
  setDataSource: (products: any[]) => void;
  dataSource: any[];
  setDataSourceIsLoading: (isLoading: boolean) => void;
  dataSourceIsLoading: boolean;
  dataSourceError: { isError: boolean; errorMessage: string; error?: any; };
  setDataSourceError: (error: {isError: boolean; errorMessage: string; error?: any; }) => void;
  // DataSource update
  updateDataSourceIsLoading: boolean;
  setUpdateDataSourceIsLoading: (isLoading: boolean) => void;
  updateDataSourceError: { isError: boolean; errorMessage: string; error?: any; };
  setUpdateDataSourceError: (error: {isError: boolean; errorMessage: string; error?: any; }) => void;
  // Filters
  dataGridfilterStr: string | undefined;
  setDataGridFilterStr: (filterStr: string | undefined) => void;    
  // Stack
  updateStack: IStackDataItem[];
  setUpdateStack: (updateFn: (prevStack: IStackDataItem[]) => IStackDataItem[]) => void;
  // Cells
  cellSelection: {[key: string]: boolean};
  setCellSelection: (cellSelection: {[key: string]: boolean}) => void;
  firstSelectedCellKey: string | null;
  setFirstSelectedCellKey: (cellId: string | null) => void;
  // Row selection
  selectedRows: TypeRowSelection;
  setSelectedRows: (selectedRows: TypeRowSelection) => void;
  // Checkbox
  toggleCheckboxColumnActive: () => void;
  setCheckBoxColumnActive: (active: boolean) => void;
  checkboxColumnActive: boolean;
  // Update/Save grid data
  isSaveReportModalVisible: boolean;
  setIsSaveReportModalVisible: (visible: boolean) => void;
  reportsSummary: SaveStackDataResult;
  setReportsSummary: (data: SaveStackDataResult) => void;
  // Cleanup
  clearDataGridStoreOnLeave: () => void;
  clearDataGridStoreOnFetch: () => void;
  // Active filterSelector
  activeFilterSelector: "groupSelector" | "workTaskSelector" | undefined;
  setActiveFilterSelector: (selector: "groupSelector" | "workTaskSelector" | undefined) => void;  
};

const useDataGridStore = create<DataGridStore>((set) => ({
  // DataSource fetch
  dataSource: [],
  setDataSource: (products) => set({ dataSource: products }),
  dataSourceIsLoading: false,
  setDataSourceIsLoading: (isLoading) =>
    set({ dataSourceIsLoading: isLoading }),
  dataSourceError: { isError: false, errorMessage: "", error: null },
  setDataSourceError: ({ isError, errorMessage, error }) =>
    set({ dataSourceError: { isError, errorMessage, error } }),
  // DataSource update
  updateDataSourceIsLoading: false,
  setUpdateDataSourceIsLoading: (isLoading) =>
    set({ updateDataSourceIsLoading: isLoading }),
  updateDataSourceError: { isError: false, errorMessage: "", error: null },
  setUpdateDataSourceError: ({ isError, errorMessage, error }) =>
    set({ updateDataSourceError: { isError, errorMessage, error } }),
  // Filters
  dataGridfilterStr: undefined,
  setDataGridFilterStr: (filterStr) => set({ dataGridfilterStr: filterStr }),
  // Stack
  updateStack: [],
  setUpdateStack: (updateFn) =>
    set((state) => ({ updateStack: updateFn(state.updateStack) })),
  // Cells
  cellSelection: {},
  setCellSelection: (cellSelection) => set({ cellSelection }),
  firstSelectedCellKey: null,
  setFirstSelectedCellKey: (cellKey) => set({ firstSelectedCellKey: cellKey }),
  // Row selection
  selectedRows: {},
  setSelectedRows: (selectedRows) => set({ selectedRows }),
  // Checkbox
  checkboxColumnActive: false,
  setCheckBoxColumnActive: (active) => set({ checkboxColumnActive: active }),
  toggleCheckboxColumnActive: () => set(state => ({ checkboxColumnActive: !state.checkboxColumnActive })),
  // Update/Save grid data
  isSaveReportModalVisible: false,
  setIsSaveReportModalVisible: (visible) => set({ isSaveReportModalVisible: visible }),
  reportsSummary: { success: [], failure: [] },
  setReportsSummary: (data) => set({ reportsSummary: data }),
  // Active filterSelector
  activeFilterSelector: undefined,
  setActiveFilterSelector: (selector) => set({ activeFilterSelector: selector }),
  // Cleanup
  clearDataGridStoreOnLeave: () =>
    set({
      dataGridfilterStr: undefined,
      activeFilterSelector: undefined,
      dataSource: [],
      selectedRows: {},
      dataSourceIsLoading: false,
      dataSourceError: { isError: false, errorMessage: "", error: null },
      updateStack: [],
      updateDataSourceIsLoading: false,
      updateDataSourceError: { isError: false, errorMessage: "", error: null },
      cellSelection: {},
      firstSelectedCellKey: null,
      checkboxColumnActive: false,
    }),
    clearDataGridStoreOnFetch: () =>
      set({
        // dataGridfilterStr: undefined,
        dataSource: [],
        selectedRows: {}, 
        dataSourceIsLoading: false,
        dataSourceError: { isError: false, errorMessage: "", error: null },
        updateStack: [],
        updateDataSourceIsLoading: false,
        updateDataSourceError: { isError: false, errorMessage: "", error: null },
        cellSelection: {},
        firstSelectedCellKey: null,
        checkboxColumnActive: false,
      }),

}));

export default useDataGridStore;