import React, {
  MutableRefObject,
  useCallback,
  useState,
} from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import {
  TypeComputedProps,
  TypeEditInfo,
  TypeColumn,
  TypeRowSelection,
} from "@inovua/reactdatagrid-community/types";
import useDataGrid from "components/hooks/dataGrid/useDataGrid";
import useDataGridStore from "stores/dataGridStore";
import ToolBarGrid from "components/dataGrid/toolBarGrid";
import { useHandleDataGridExit } from "components/hooks/useHandleComponentExit";
import SaveTableDataLoader from "components/dataGrid/loadIndicators/saveTableDataLoader";
import FooterGrid from "components/dataGrid/footerGrid";
import useDataGridLoading from "components/hooks/dataGrid/useDataGridLoading";
import SaveReportModal from "components/dataGrid/modals/saveReportModal";
import RenderUpdateStackDevComp from "components/dataGrid/renderUpdateStackDevComp";
import {
  isFilterFieldFocused,
  updateColumnVisibilityInStorage,
} from "utility/dataGrid/helpers";
import { notification } from "antd";
import { DATA_GRID_COLUMN_VISIBILITY_STORAGE_KEY } from "utility/constants";
import styled from "@emotion/styled";

const DataGridWrapper = styled.div`
  position: relative;
  z-index: 1;
  &:has(.inovua-react-toolkit-menu__submenu-wrapper) {
    z-index: 1000;
  }
  .inovua-react-toolkit-menu__submenu-wrapper {
    z-index: 1000;
  }
`;

interface CopiedCellData {
  serializedCellValues: string;
  columnType: string | null;
  columnName: string | null;
}

const editRestricted = [
  "mainItemNo",
  "groupName",
  "ean",
  "status",
  "completenessPercentage",
];

const gridStyle = { minHeight: "80vh" };

const DataGridView: React.FC = () => {
  const [gridRef, setGridRef] =
    useState<MutableRefObject<TypeComputedProps | null> | null>(null);
  const { columns, filterValue } = useDataGrid();
  const { dataGridIsLoading } = useDataGridLoading();
  useHandleDataGridExit();
  const {
    setDataSource,
    dataSource,
    updateStack,
    setUpdateStack,
    cellSelection,
    setCellSelection,
    firstSelectedCellKey,
    setFirstSelectedCellKey,
    selectedRows,
    setSelectedRows,
    checkboxColumnActive,
  } = useDataGridStore();
  const [isEditorActive, setIsEditorActive] = useState(false);
  const [copiedCellData, setCopiedCellData] = useState<CopiedCellData>({
    serializedCellValues: "",
    columnType: null,
    columnName: null,
  });

  const onEditComplete = useCallback(
    (editInfo: TypeEditInfo) => {
      const data = [...dataSource];
      const productId = editInfo?.rowId;
      const rowToUpdateIndex = data?.findIndex(
        (items) => items?.id == productId
      );
      const oldValue = data[rowToUpdateIndex][editInfo?.columnId];

      if (editInfo.value == oldValue) return;

      data[rowToUpdateIndex][editInfo.columnId] = editInfo.value;
      setDataSource(data);
      setUpdateStack((prevStack) => [
        ...prevStack,
        {
          cell: `${productId},${editInfo.columnId}`,
          value: oldValue,
          revisionId: generateRandomRevisionId(),
        },
      ]);
    },
    [dataSource]
  );

  const onCellSelectionChange = (value: { [key: string]: boolean }) => {
    const selectedCellKeys = Object.keys(value);
    const selectedCellCount = selectedCellKeys?.length;

    if (selectedCellCount === 0) {
      return;
    }

    // Single cell selection - set as a new starting point
    if (selectedCellCount === 1) {
      setFirstSelectedCellKey(selectedCellKeys[0]);
      setCellSelection(value);
      return;
    }

    const validColumnName = getColumnKeyFromCell(firstSelectedCellKey);

    // Filter to only include cells in the same column as the first selected cell
    const validCells = selectedCellKeys?.reduce((acc, key) => {
      const currentColumnName = getColumnKeyFromCell(key);
      if (currentColumnName === validColumnName) {
        acc[key] = true;
      }
      return acc;
    }, {} as { [key: string]: boolean });

    setCellSelection(validCells);
  };

  const updateAndParseSelectedCell = (
    selectedCell: string,
    newValue: any,
    revisionId: number
  ) => {
    const selectedRowId: number = parseInt(selectedCell.split(",")[0]);
    const selectedColumnProp: string = selectedCell.split(",")[1];
    const data = [...dataSource];
    const indexToUpdate = data?.findIndex((data) => data.id === selectedRowId);
    const oldValue = data[indexToUpdate][selectedColumnProp];
    const column = columns?.find((c) => c?.name == selectedColumnProp);

    if (column?.type == "array" && typeof newValue === "string") {
      newValue = newValue
        .split(",")
        .map((id: string) => ({ id: parseInt(id) }));
    }

    if (!isNaN(newValue) && typeof newValue === "string") {
      newValue = parseInt(newValue);
    }

    if (newValue == "false") {
      newValue = false;
    }

    if (column?.type == "arrayObject" && typeof newValue === "string") {
      newValue = newValue.split("|").map((item: string) => JSON.parse(item));
    }

    data[indexToUpdate][selectedColumnProp] = newValue;
    setDataSource(data);
    setUpdateStack((prevStack) => [
      ...prevStack,
      { cell: selectedCell, value: oldValue, revisionId: revisionId },
    ]);
  };

  const generateRandomRevisionId = () => {
    const revisionId = Math.floor(Math.random() * 10000);
    return revisionId;
  };

  const performMultiCellUpdate = (selectedCells: string[], newValue: any) => {
    const revisionId = generateRandomRevisionId();
    for (const selectedCell of selectedCells) {
      updateAndParseSelectedCell(selectedCell, newValue, revisionId);
    }
  };

  const getProductIdFromCell = (cellKey: string | null) => {
    return parseInt(cellKey?.split(",")[0] ?? "0");
  };

  const getColumnKeyFromCell = (cellKey: string | null) => {
    return cellKey?.split(",")[1] ?? "";
  };

  const getFirstCellKey = (cellSelection: { [key: string]: boolean }) => {
    return Object.keys(cellSelection)[0];
  };

  const getColumnByName = (columns: TypeColumn[], columnName: string) => {
    return columns?.find((column) => column?.name === columnName);
  };

  const extractColumnsForMultiCellUpdate = () => {
    if (Object.keys(cellSelection).length === 0) {
      return;
    }

    const productId = getProductIdFromCell(firstSelectedCellKey);
    const columnKey = getColumnKeyFromCell(firstSelectedCellKey);
    const valueToCopy = dataSource?.find((data) => data.id === productId)[
      columnKey
    ];

    if (editRestricted.includes(columnKey)) {
      return;
    }

    const cellsToUpdate = Object.keys(cellSelection).filter(
      (cell) =>
        cell != firstSelectedCellKey && getColumnKeyFromCell(cell) === columnKey
    );
    performMultiCellUpdate(cellsToUpdate, valueToCopy);
  };

  const handleCopy = (): void => {
    const selectedCell = getFirstCellKey(cellSelection);
    const productId = getProductIdFromCell(selectedCell);
    const columnKey = getColumnKeyFromCell(selectedCell);

    const valueToCopy = dataSource?.find((data) => data?.id === productId)?.[
      columnKey
    ];

    const column = getColumnByName(columns, columnKey);

    let serializedValue;
    if (column?.type === "array") {
      serializedValue =
        valueToCopy?.map((item: any) => item)?.join(",") ?? "[]";
    } else if (column?.type === "arrayObject") {
      serializedValue = valueToCopy?.length
        ? valueToCopy?.map((item: any) => JSON.stringify(item))?.join("|")
        : "[]";
    } else {
      serializedValue = valueToCopy?.toString() ?? "";
    }

    setCopiedCellData({
      serializedCellValues: serializedValue,
      columnType: column?.type ?? null,
      columnName: column?.name ?? null,
    });
  };

  const handlePaste = (): void => {
    const targetCell = getFirstCellKey(cellSelection);
    const targetColumnKey = getColumnKeyFromCell(targetCell);
    const targetColumn = getColumnByName(columns, targetColumnKey);

    const isInvalidPasteTarget =
      targetColumn?.type !== copiedCellData?.columnType ||
      copiedCellData?.columnName !== targetColumnKey;

    if (isInvalidPasteTarget) {
      notification.error({
        message: "Felaktig inmatning",
        description: `Cellen i kolumnen ${targetColumn?.header} kan inte hantera ditt kopierade värde.`,
        placement: "topRight",
        duration: 2,
      });
      return;
    }

    performMultiCellUpdate(
      Object.keys(cellSelection),
      copiedCellData?.serializedCellValues
    );
  };

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      // Prevent dataGrid keyboard KeyboardEvents when editor is active/open or filter field is focused
      if (isEditorActive || isFilterFieldFocused()) return;

      try {
        const isCommandKey = event.ctrlKey || event.metaKey;
        switch (true) {
          case event.key === "Escape":
            setCellSelection({});
            break;

          case event.key === "Enter":
            extractColumnsForMultiCellUpdate();
            break;

          case isCommandKey && event.key === "z":
            // Handle undo stack update
            break;

          case isCommandKey && event.key === "c":
            handleCopy();
            break;

          case isCommandKey && event.key === "v":
            handlePaste();
            break;
        }
      } catch (error) {
        notification.error({
          message: "Error",
          description:
            "Ett fel uppstod vid hantering av ett tangentbordskommando.",
          placement: "topRight",
        });
      }
    },
    [cellSelection, columns, isEditorActive]
  );

  const onBlur = useCallback((event: any) => {
    setCellSelection({});
  }, []);

  const onRowSelectionChange = useCallback(
    ({ selected }: { selected: TypeRowSelection }) => {
      setSelectedRows(selected);
    },
    []
  );

  const handleColumnVisibleChange = ({
    column,
    visible,
  }: {
    column: TypeColumn;
    visible: boolean;
  }): void => {
    if (!column?.name) return;
    updateColumnVisibilityInStorage(
      DATA_GRID_COLUMN_VISIBILITY_STORAGE_KEY,
      column?.name,
      visible
    );
  };

  const onEditStart = useCallback(() => {
    setIsEditorActive(true);
  }, []);

  const onEditStop = useCallback(() => {
    setIsEditorActive(false);
  }, []);

  return (
    <>
      {/* <RenderUpdateStackDevComp /> */}
      <ToolBarGrid />
      <DataGridWrapper onKeyDown={handleKeyDown}>
        <ReactDataGrid
          idProperty="id"
          defaultFilterValue={filterValue}
          loading={dataGridIsLoading}
          onReady={setGridRef}
          columns={columns}
          onEditComplete={onEditComplete}
          dataSource={dataSource}
          activateRowOnFocus={false}
          onBlur={onBlur}
          shareSpaceOnResize={true}
          style={gridStyle}
          emptyText={"Ingen data tillgänglig"}
          key={"grid-" + checkboxColumnActive}
          checkboxColumn={checkboxColumnActive}
          editable={!checkboxColumnActive}
          onSelectionChange={
            checkboxColumnActive ? onRowSelectionChange : undefined
          }
          selected={checkboxColumnActive ? selectedRows : undefined}
          cellSelection={!checkboxColumnActive ? cellSelection : undefined}
          onCellSelectionChange={
            !checkboxColumnActive ? onCellSelectionChange : undefined
          }
          onColumnVisibleChange={handleColumnVisibleChange}
          clearNodeCacheOnDataSourceChange={true} // Clear the cache when the data source changes, keep data in sync with ui
          // menuPortalContainer={document.getElementById('grid-menu-container')}
          // onFilterValueChange={onFilterValuesChange}
          // renderPaginationToolbar={() => <FooterGrid itemsCount={dataSource?.length} />}
          // checkboxOnlyRowSelect={true}
          // clearNodeCacheOnDataSourceChange={true}
          onEditStart={onEditStart}
          onEditStop={onEditStop}
        />
      </DataGridWrapper>
      <FooterGrid itemsCount={dataSource?.length ?? 0} />
      <SaveTableDataLoader />
      <SaveReportModal />
    </>
  );
};

export default DataGridView;
