import { useEffect, useRef, useState } from 'react';
import { useOpenApiClient } from './base/useOpenApiClient';
import { ErrorResponse, GetManyRequestOptions } from 'interfaces/baseTypes';
import { ClientNotReadyError, EntityMetaData, PatchRequest, ResponseWrapperMany, WorkTask } from 'interfaces/apiTypes';

export interface WorkTaskOne {
    metaData: EntityMetaData;
    item: WorkTask;
}

const useWorkTaskOpenApi = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<ErrorResponse | null>(null);
    const {client, getClient} = useOpenApiClient();
    const clientRef = useRef(client);

    useEffect(() => {
        clientRef.current = client;
    }, [client]);

    async function getWorkTaskById(id: number): Promise<WorkTaskOne | undefined>
    {
        setLoading(true);
        setErrorMessage(null);
        try {
            if(!clientRef.current){
                clientRef.current = getClient();
            }
            const response = await clientRef.current.GET('/api/worktask/{id}', {
                params: { path: { id } }
            });

            const error = response?.error as unknown as ErrorResponse;
            if (error) {
                setErrorMessage(error);
                return undefined;
            }

            return response?.data as WorkTaskOne | undefined;
        } catch (e: any) {
            console.log("error", e);
            setErrorMessage(e.message || 'An error occurred');
            return undefined;
        } finally {
            setLoading(false);
        }
    }

    async function getWorkTasks(options: GetManyRequestOptions = {}): Promise<ResponseWrapperMany<WorkTask> | undefined>
    {
        const { page = 0, pageSize = 10, order, orderByField, filters } = options;

        setLoading(true);
        setErrorMessage(null);
        try {
            if(!clientRef.current){
                clientRef.current = getClient();
            }
            const response = await clientRef.current?.GET('/api/worktask', {
                params: { query: { page, pageSize, order, orderByField, filters } }
            });

            const error = response?.error as unknown as ErrorResponse;
            if (error) {
                setErrorMessage(error);
                return undefined;
            }

            return response?.data as ResponseWrapperMany<WorkTask>;
        } catch (e: any) {
            setErrorMessage(e.message || 'An error occurred');
            return undefined;
        } finally {
            setLoading(false);
        }
    }

    //create work task same as path pattern
    async function createWorkTask(workTaskCreateRequest: PatchRequest): Promise<boolean>
    {
        setLoading(true);
        setErrorMessage(null);
        try {
            if(!clientRef.current){
                clientRef.current = getClient();
            }
            await clientRef.current?.POST('/api/worktask', {
                body: workTaskCreateRequest,
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return true;
        }catch (e: any) {
            setErrorMessage(e.message || 'An error occurred');
            return false;
        } finally {
            setLoading(false);
        }
    }

    async function patchWorkTask(id: number, workTaskPatch: PatchRequest): Promise<boolean>
    {
        setLoading(true);
        setErrorMessage(null);
        try {
            if(!clientRef.current){
                clientRef.current = getClient();
            }
            const response = await clientRef.current?.PATCH('/api/worktask/{id}', {
                params: { path: { id } },
                body: workTaskPatch,
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const error = response?.error as unknown as ErrorResponse;
            if (error) {
                setErrorMessage(error);
                return false;
            }
            return true;
        } catch (e: any) {
            setErrorMessage(e.message || 'An error occurred');
            return false;
        } finally {
            setLoading(false);
        }
    }

    async function deleteWorkTask(id: number): Promise<void>
    {
        setLoading(true);
        setErrorMessage(null);
        try {
            if(!clientRef.current){
                clientRef.current = getClient();
            }
            await clientRef.current?.DELETE('/api/worktask/{id}', {
                params: { path: { id } }
            });
        } catch (e: any) {
            setErrorMessage(e.message || 'An error occurred');
        } finally {
            setLoading(false);
        }
    }

    async function getMetadata(): Promise<EntityMetaData | undefined>
    {
        setLoading(true);
        setErrorMessage(null);
        try {
            if(!clientRef.current){
                clientRef.current = getClient();
            }
            const response = await clientRef.current?.GET('/api/worktask/metadata');

            const error = response?.error as unknown as ErrorResponse;
            if (error) {
                setErrorMessage(error);
                return undefined;
            }
            return response?.data as EntityMetaData;
        } catch (e: any) {
            setErrorMessage(e.message || 'An error occurred');
            return undefined;
        } finally {
            setLoading(false);
        }
    }

    async function addProductsToWorkTask(id: number, articleNumbers: string[]): Promise<boolean> {
        setLoading(true);
        setErrorMessage(null);
        try {
            await clientRef.current?.PATCH(`/api/worktask/{id}/add-items`, {
                params: { path: { id } },
                body: { articleNumbers }
            });
            return true;
        } catch (e: any) {
            setErrorMessage(e.message || 'An error occurred');
            return false;
        } finally {
            setLoading(false);
        }
    }

    return { getWorkTasks, getWorkTaskById, patchWorkTask, createWorkTask, addProductsToWorkTask, deleteWorkTask, getMetadata, loading, errorMessage };
}

export default useWorkTaskOpenApi;
