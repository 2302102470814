import { Button } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  ColumnConfig,
  DEFAULT_LIST_COL_HEIGHT_PX,
  SimpleListView,
} from "components/listViews/simpleListView";
import useWorkTaskOpenApi from "apiClients/useWorkTaskOpenApiClient";
import { FilterGroup, FilterItem, FilterOperatorEnum, LogicalOperatorEnum } from "interfaces/baseTypes";
import { SectionHeader } from "components/layout/header/sectionHeader";
import useWorkTaskStore from "stores/workTaskStore";
import { DividerLine } from "components/separators/dividerLine";
import { enhanceProduct, Product, WorkTask } from "interfaces/apiTypes";
import { ListChecks } from "lucide-react";
import WorkTaskSectionHeader from "./header/sectionHeader";
import { defaultSectionHeaderIconStyle } from "../styles/workTaskStyles";
import useProductOpenApi from "apiClients/useProductOpenApiClientV2";
import { useLocaleStore } from "stores/localeStore";
import { ILanguage } from "interfaces";
import { WORK_TASKS_PRODUCTS_QUERY_KEY } from "utility/constants";
import { parseFiltersToString, parseStringToFilters } from "utility/helper";


const getWorkTaskItemsColumns = (activeLanguage: ILanguage | null): ColumnConfig<Product>[] => [
  {
    header: "ArtNr",
    accessorKey: "mainItemNo",
    cell: ({ row }) => (
      <>
       {(enhanceProduct(row.original, activeLanguage?.id)?.getItemNumber()) ?? "N/A"}
      </>
    ),
    size: 100,
    sort: true,
  },
  {
    header: "Namn",
    accessorKey: "name",
    cell: ({ row }) => (
      <>
        {enhanceProduct(row.original, activeLanguage?.id)?.getName?.() ?? "N/A"}
      </>
    ),
    size: 200,
  },
  {
    header: "Varumärke",
    accessorKey: "brand",
    cell: ({ row }) => (
      <>
        {enhanceProduct(row.original, activeLanguage?.id)?.brand?.name ?? "N/A"}
      </>
    ),
    size: 100,
  },
];

const WorkTaskItems: React.FC<{ sectionHeaderTitle?: string }> = ({
  sectionHeaderTitle,
}) => {
  const { currentFilterGroup, selectedWorkTask } = useWorkTaskStore();
  const { getProducts } = useProductOpenApi();
  const { activeLanguage } = useLocaleStore();
  const [totalCount, setTotalCount] = useState(0);
  const [localFilterGroup, setLocalFilterGroup] = useState<FilterGroup | FilterItem | null>(null);

  const columns = getWorkTaskItemsColumns(activeLanguage);


  useEffect(() => {
    let filterGroup = {...currentFilterGroup} as FilterGroup;

    if (selectedWorkTask && selectedWorkTask.items && selectedWorkTask.items.length > 0) {
      const productIds = selectedWorkTask.items.map(item => item?.productId ?? 0) ?? [];

      const staticFilter = {
        operator: LogicalOperatorEnum.AND,
        value: [
          {
            fieldName: "id",
            operator: FilterOperatorEnum.IN,
            value: productIds.join(","),
          },
          filterGroup
        ],
      } as FilterGroup

      setLocalFilterGroup(staticFilter);

      return;
    }

    setLocalFilterGroup(currentFilterGroup);
  
  }, [selectedWorkTask, currentFilterGroup]);
  
  return (
    <div>
      {/* <pre>{JSON.stringify(localFilterGroup, null, 2)}</pre>
      <pre>{parseFiltersToString(localFilterGroup as FilterGroup, true)}</pre> */}
      <div style={{ position: "sticky", top: 0, zIndex: 11 }}>
        <WorkTaskSectionHeader
          title={sectionHeaderTitle ?? ""}
          subText={`(${totalCount}) st`}
          icon={<ListChecks style={defaultSectionHeaderIconStyle} />}
          showSeparatorLine={true}
        />
      </div>
      <SimpleListView<Product>
        queryKey={WORK_TASKS_PRODUCTS_QUERY_KEY}
        resizableColumns={false}
        selectable={false}
        fetchItems={async (
          page,
          pageSize,
          sortOrder,
          orderByField,
          filterString
        ) =>
          await getProducts({
            page: page,
            pageSize: pageSize,
            order: sortOrder,
            orderByField: orderByField,
            filters: filterString,
          })
        }
        columns={columns}
        renderHeader={() => {
          return <React.Fragment></React.Fragment>;
        }}
        onCountChange={(count) => {
          setTotalCount(count);
        }}
        filterGroup={localFilterGroup as FilterGroup}
        listLoadingHeightPx={DEFAULT_LIST_COL_HEIGHT_PX * 5}
      />
    </div>
  );
};

export default WorkTaskItems;
