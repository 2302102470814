import { Tooltip } from "antd";
import styled from "@emotion/styled";
import { ExclamationCircleTwoTone, LockTwoTone } from "@ant-design/icons";

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  cursor: pointer;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
`;

interface LockedMenuOverlayProps {
  handleUnlockClick: () => void;
  lockedMenu: boolean;
  message?: string;
}
const LockedMenuOverlay = ({
  handleUnlockClick,
  lockedMenu,
  message,
}: LockedMenuOverlayProps) => {
  if (!lockedMenu) return null;
  return (
    <Overlay onClick={handleUnlockClick}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <Tooltip title={message ?? "Hantera låst meny"}>
          <LockTwoTone
            twoToneColor="#153F65"
            style={{ fontSize: "20px" }}
          />
        </Tooltip>
      </div>
    </Overlay>
  );
};

export default LockedMenuOverlay;