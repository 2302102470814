import { useModal } from "@refinedev/core";
import { Modal } from "antd";

interface LockedMenuOverlayProps {
  isModalVisible: boolean;
  confirmUnlock: () => void;
  cancelUnlock: () => void;
  title?: string;
  message?: string;
  okText?: string;
  cancelText?: string;
}

const LockedSwitchModal = ({isModalVisible, confirmUnlock, cancelUnlock, title, message, okText, cancelText}: LockedMenuOverlayProps) => {

  return (
    <Modal
      title={title ?? "Radmarkering är låst."}
      open={isModalVisible}
      onOk={confirmUnlock}
      onCancel={cancelUnlock}
      okText={okText ?? "Ok"}
      cancelText={cancelText ?? "Avbryt"}
    >
      <p>
        {message ?? "Du har har osparade ändringar i din tabelldata. För att låsa upp radmarkeringen måste du först spara dina ändringar eller ladda om tabellen."}
      </p>
    </Modal>
  );
};

export default LockedSwitchModal;
